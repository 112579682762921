import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../utils/api";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles
} from "@material-ui/core";
import Loader from "../../components/misc/loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useGlobalStyles from "../../utils/global-style";
import CustomActionButton from "../../components/common/CustomActionButton";
import { Print } from "@material-ui/icons";
import ReactToPrint from 'react-to-print';
import PatientInfoCard from './ipd/PatientInfoCard';
import InputOutputTable from './ipd/InputOutputTable';
import './ipd/IOVitals.scss';
import AuthService from "../../utils/services/auth-service";
import PrintComponent from "../../components/common/PrintComponent";

const useStyles = makeStyles((theme) => ({
  scrollableContent: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '@media print': {
      overflowY: 'visible',
      height: 'auto !important',
    },
  },
}));

const Compare = () => {
  const globalClass = useGlobalStyles();
  const classes = useStyles();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [receiptDate, setReceiptDate] = useState("");
  const [timeStampforDatepicker, setTimeStampforDatepicker] = useState(null);
  const [receiptDate_1, setReceiptDate_1] = useState("");
  const [timeStampforDatepicker_1, setTimeStampforDatepicker_1] =
    useState(null);

  const [totalInput, setTotalInput] = useState(null);
  const [totalOutput, setTotalOutput] = useState(null);

  useEffect(() => {
    // Api.get(`admit/inputoutput/total/${id}${timeStampforDatepicker ? `?createdAt=${timeStampforDatepicker}` : ''}`).subscribe((resp) => {
    Api.get(
      `admit/inputoutput/total/${id}${
        timeStampforDatepicker_1 && timeStampforDatepicker
          ? `?createdAt=${timeStampforDatepicker_1}-${timeStampforDatepicker}`
          : ""
      }`
    ).subscribe((resp) => {
      setTotalInput(resp.data.data?.totalInput);
      setTotalOutput(resp.data.data?.totalOutput);
    });
  }, [timeStampforDatepicker]);

  const [patientStatus, setPatientStatus] = useState();
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState();
  const [plan, setPlan] = useState();
  const [roomNumber, setRoomNumber] = useState();
  const [doctor, setDoctor] = useState();
  const [patientName, setPatientName] = useState();
  const [doctorName, setDoctorName] = useState();
  const [patientUhid, setPatientUhid] = useState();

  useEffect(() => {
    Api.get(`admit/id/${id}`).subscribe((resp) => {
      let admitdata = resp.data.data;
      setDoctorName(admitdata?.doctor?.name);
      setPatientName(admitdata?.patient?.name);
      setPatientUhid(admitdata?.patient?.uhid);
      setRoomNumber(admitdata?.roomNumber);
      setProvisionalDiagnosis(admitdata?.prov);
      setPlan(admitdata?.plan);
      setPatientStatus(admitdata?.status);
      setDoctor(admitdata?.doctor);
    });
  }, []);

  const [isloading, setIsLoading] = useState(false);
  const [AdmitHistoryData, setAdmitHistoryData] = useState([]);
  const [content, setContent] = useState({});
  useEffect(() => {
    setIsLoading(true);
    Api.post("admit/history/search", {
      size: rowsPerPage,
      page: page,
      filterModel: {
        admitId: {
          type: "equals",
          // "value": "6478601cb15649193601d60b"
          value: id,
        },
        inputOutput: {
          type: "custom",
          value: "exists",
        },
        // "createdAt": {
        //     "type": "equals",
        //     // "value": 1686913936000

        //     "value": timeStampforDatepicker
        // },
        vitals: {
          type: "custom",
          value: "exists",
        },
        createdAt:
          timeStampforDatepicker_1 && timeStampforDatepicker
            ? {
                type: "custom",
                // "value": "1686900179000-1687277207000"
                value: `${timeStampforDatepicker_1}-${timeStampforDatepicker}`,
              }
            : null,
      },
      sortModel: [],
    }).subscribe((resp) => {
      setContent(resp?.data?.data);
      setAdmitHistoryData(resp?.data?.data?.content);
      setIsLoading(false);
    });
  }, [timeStampforDatepicker, page, rowsPerPage]);

  const onReceiptDateSelected = (dateInput) => {
    setTimeStampforDatepicker(dateInput ? dateInput.valueOf() : null);
    setReceiptDate(dateInput);
  };
  const onReceiptDateSelected_1 = (dateInput) => {
    setTimeStampforDatepicker_1(dateInput ? dateInput.valueOf() : null);
    setReceiptDate_1(dateInput);
  };

  const clearDate = () => {
    setTimeStampforDatepicker(null);
    setTimeStampforDatepicker_1(null);
    setReceiptDate("");
    setReceiptDate_1("");
    setPage(0);
  };

  const componentRef = useRef();

  const PrintContent = () => (
    <div>
      <PatientInfoCard
        patientName={patientName}
        roomNumber={roomNumber}
        doctorName={doctorName}
        patientUhid={patientUhid}
        provisionalDiagnosis={provisionalDiagnosis}
        plan={plan}
        patientStatus={patientStatus}
      />

      {!isloading && (
        <InputOutputTable
          content={content}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      {!isloading && (
        <Box mt={2} p={2}>
          <Typography>
            Total Input {receiptDate ? "for Date" : ""}: {totalInput}
          </Typography>
          <Typography>
            Total Output {receiptDate ? "for Date" : ""}: {totalOutput}
          </Typography>
        </Box>
      )}
    </div>
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box flexShrink={0} style={{padding: "20px"}}>
        <div className="row" style={{ padding: "15px" }}>
          <div className="col-md-4">
            <div>Input Output/Vitals</div>
          </div>
          <div className="col-md-8 text-end">
            <Grid container spacing={2} alignItems="center" justify={isMobile ? "center" : "flex-end"} direction={isMobile ? "column" : "row"}>
              <Grid item xs={isMobile ? 12 : 'auto'}>
                <DatePicker
                  label="From Date"
                  value={receiptDate_1 || null}
                  onChange={onReceiptDateSelected_1}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth={isMobile} />
                  )}
                  inputFormat="DD/MM/YYYY"
                  className={globalClass.roundWhiteBg}
                  format="DD/MM/YYYY"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 'auto'}>
                <DatePicker
                  label="To Date"
                  value={receiptDate || null}
                  onChange={onReceiptDateSelected}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" fullWidth={isMobile} />
                  )}
                  inputFormat="DD/MM/YYYY"
                  className={globalClass.roundWhiteBg}
                  format="DD/MM/YYYY"
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 'auto'}>
                <CustomActionButton
                  variant="contained"
                  color="primary"
                  onClick={clearDate}
                  className={globalClass.customActionButton}
                  buttonTitle="Clear"
                  fullWidth={isMobile}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 'auto'}>
                <ReactToPrint
                  trigger={() => (
                    <Tooltip title='Print'>
                      <IconButton>
                        <Print style={{ fontSize: "24px" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  content={() => componentRef.current}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>

      <Box display="flex" flexDirection="column" flexGrow={1} overflow="hidden" style={{padding: "20px"}}>
        <Box flexShrink={0}>
          <PatientInfoCard
            patientName={patientName}
            roomNumber={roomNumber}
            doctorName={doctorName}
            patientUhid={patientUhid}
            provisionalDiagnosis={provisionalDiagnosis}
            plan={plan}
            patientStatus={patientStatus}
          />
        </Box>

        <Box flexGrow={1} overflow="auto" className={classes.scrollableContent}>
          {isloading ? (
            <Loader />
          ) : (
            <>
              <InputOutputTable
                content={content}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <Box mt={2} p={2}>
                <Typography>
                  Total Input {receiptDate ? "for Date" : ""}: {totalInput}
                </Typography>
                <Typography>
                  Total Output {receiptDate ? "for Date" : ""}: {totalOutput}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <PrintComponent
        Header={PrintHeader}
        Footer={PrintFooter}
        Content={PrintContent}
        reference={componentRef}
      />
    </Box>
  );
};

const PrintHeader = () => (
  <img src={AuthService.getConfig().ipdConfig?.receiptHeaderUrl} alt="Header" />
);


const PrintFooter = () => (
  <img src={AuthService.getConfig().ipdConfig?.receiptFooterUrl} alt="Footer" />
);

export default Compare;
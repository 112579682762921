import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";

const InputOutputTable = ({
  content,
}) => {
  // Check if content and content.content exist
  if (!content || !content.content || content.content.length === 0) {
    return (
      <Box p={2}>
        <Typography>No data available</Typography>
      </Box>
    );
  }

  // Group rows by date
  const groupedContent = content.content.reduce((acc, row) => {
    const date = moment(row.createdAt).format("DD-MM-YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    // acc[date].push(row);
    acc[date].push(row);
    return acc;
  }, {});

  return (
    <TableContainer
      style={{
        backgroundColor: "#fff",
        borderRadius: "10px",
        border: "1px solid grey",
        flex: 1,
        overflowY: "auto",
      }}
      className="io-table-container"
    >
      <Table size="small" aria-label="input output table">
        <TableHead>
          <TableRow>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Pr
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              BP
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              SPO2
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Temp
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              RR
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Pain score
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              RBS
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Input
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Input Type
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Output
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Output Type
            </TableCell>
            <TableCell style={{ borderRight: "1px solid grey" }} align="center">
              Username
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedContent).map(([date, rows]) => (
            <React.Fragment key={date}>
              <TableRow>
                <TableCell
                  colSpan={12}
                  align="center"
                  style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  {date}
                </TableCell>
              </TableRow>
              {rows.map((row, index) => (
                <TableRow key={`${date}-${index}`}>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.vitals?.pulseRate}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.vitals?.bloodPressure}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.vitals?.oxygenSaturation}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.vitals?.temperature}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.vitals?.respirationRate}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.vitals?.ps}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="center"
                  >
                    {row?.vitals?.remarks}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.inputOutput?.inputOutputType === "input"
                      ? `${row?.inputOutput?.volume}${row?.inputOutput?.unit}`
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="center"
                  >
                    {row?.inputOutput?.inputOutputType === "input"
                      ? row?.inputOutput?.valueType
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="right"
                  >
                    {row?.inputOutput?.inputOutputType === "output"
                      ? `${row?.inputOutput?.volume}${row?.inputOutput?.unit}`
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="center"
                  >
                    {row?.inputOutput?.inputOutputType === "output"
                      ? row?.inputOutput?.valueType
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{ borderRight: "1px solid grey" }}
                    align="center"
                  >
                    {row?.createdByName}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InputOutputTable;

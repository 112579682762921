import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";

import {
    Box,
    Grid,
    makeStyles,
    Button,
    Typography,
    FormControlLabel,
    CircularProgress,
    TextField,
} from "@material-ui/core";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { format } from "date-fns";
import { Form } from "informed";
import { Radio, RadioGroup, FormLabel, Chip } from "@mui/material";
import { CustomDateInput, SelectInput, TextInput } from "../../components/sixsprint-react-ui";
import { ThemeProvider, createTheme } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { SHOW_SUCCESS_NOTIFICATION } from "../../utils/app-util";
import { SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import Autocomplete from "../../components/misc/autocomplete";
import Routes from "../../routes/routes";
import { useHistory } from "react-router-dom";
import Loader from "../../components/misc/loader";
import Api from "../../utils/api";
import Api2 from "../../utils/api2";
import { AddCircleOutline } from "@material-ui/icons";
import { useAdmitPatient, useGetAllActiveRooms, useSearchDoctors } from "../../apis/AdmitAPI";
import { useGetProfileById } from "../../apis/ProfileAPI";
import { useGetAppointmentByPatientSlug } from "../../apis/AppointmentAPI";
import { SearchAdmit } from "../../apis/AdmitAPI";
import Header from "../../components/layout/header";

const useStyles = makeStyles((theme) => ({
  modalStyle_1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "40%",
    background: "white",
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    // overflowX: "scroll",
    // '&::-webkit-scrollbar': {
    //     width: '0.4em'
    // },
    // '&::-webkit-scrollbar-track': {
    //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: 'rgba(0,0,0,.1)',
    //     outline: '1px solid slategrey'
    // }
  },
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    paddingTop: "1rem",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  input: {
    display: "none",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  container: {
    padding: theme.spacing(10),
  },
  textField: {
    width: "80%",
  },

  printBtnContainer: {
    width: "100%",
    padding: theme.spacing(6),
  },

  loaderStyle: {
    color: "white",
  },
  dialogBackground: {
    backgroundColor: theme.palette.bg,
    width: "90%",
    height: "90%",
    margin: "auto",
  },
  containerBox: {
    width: "100%",
    flexGrow: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    margin: theme.spacing(5, 0),
    padding: theme.spacing(8, 12),
  },
  presBox: {
    height: "20vh",
    overflowY: "scroll",
  },
  reportTitle: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "0.7rem",
  },
  reportClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  reportImage: {
    height: "94%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },
  multipleImageContainer: {
    width: "89%",
    maxHeight: "70%",
    margin: "0px auto",
  },
  reportMultipleImage: {
    maxHeight: "60%",
    width: "60%",
    margin: "0px auto",
    borderRadius: "15px",
  },
  reportPdf: {
    width: "80%",
    height: "500px",
    margin: "0px 70px",
  },
  noReportImage: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30%",
  },
  noReports: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reportContainer: {
    display: "flex",
  },
  closeTitle: {
    fontSze: "12px",
  },
  reportHeading: {
    alignSelf: "center",
    marginLeft: "50%",
    transform: "translate(-75%)",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  diagramContainer: {
    display: "flex",
  },
  diagramClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  closeTitle: {
    fontSze: "12px",
  },
  diagramImage: {
    height: "90%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },

  "prescription-details": {
    padding: "40px 30px",
    marginBottom: "2.5rem",

    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "20px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "20px" },
      },
    },

    "&__title": {
      color: "#1A1A1A",
      font: "bold 16px/18px Comfortaa",
      marginBottom: "8px",
    },

    "&__sub-title": {
      color: "#6D6D6D",
      font: "lighter 16px/18px Comfortaa",
      paddingBottom: "12px",
      borderBottom: "1px solid #E6E6E6",
    },
  },

  "prescription-qr": {
    textAlign: "center",
    padding: "20px 30px 30px",
    marginBottom: "2.5rem",

    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },

    "&__img": {
      maxWidth: "100%",
      margin: "-15px",
    },
  },
  vitals: {
    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "30px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "30px" },
      },
    },
  },
  otherEmailField: {
    width: "400px",
  },
  vitalSign: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "230px",
    },

    "& h6": {
      width: "40px",
    },

    "& .MuiPaper-root": {
      flex: 1,
    },
  },
  restrictionControlBox: {
    display: "flex",
    alignItems: "center",
  },
  clrBtnBox: {
    padding: 20,
  },

  imageDiv: {
    maxWidth: "100px",
    width: "100%",
    height: "100px",
    maxHeight: "100%",
    border: "1px solid black",
    borderRadius: "50%",
  },
}));

const AdmitedProfile = () => {
  // to scroll to the top when rendering
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();
  const { id2: urlPathValue } = params;
  const location = useLocation();
  // threee variables used incase of admit new patient
  let patientSlug;
  let doctorSlug;
  let appointmentSlug;
  if(isNewPatientAdmitForm(urlPathValue)) {
    const searchParams = new URLSearchParams(location.search);
    patientSlug = searchParams.get("patientSlug");
    doctorSlug = searchParams.get("doctorSlug");
    appointmentSlug = searchParams.get("appointmentSlug");
  }

  const myTheme = createTheme({
    // style radio button as button element
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: "#293264",
            margin: "5px 15px 0 0",
            padding: "2px 8px",
            width: "max-content",
            borderStyle: "none",
            border: "1px solid !important",
            borderRadius: "13px!important",
            "&.Mui-selected": {
              backgroundColor: "#D6DBF5",
              borderStyle: "none!important",
            },
            "&:hover": {
              backgroundColor: "#D6DBF5",
            },
          },
          grouped: {
            backgroundColor: "#D6DBF5",
            borderStyle: "none!important",
            "&:hover": {
              backgroundColor: "#D6DBF5",
            },
          },
        },
      },
    },
  });

  // fetch data
  const { data: allDoctorsList, isLoading: isAllDoctorsListLoading } = useSearchDoctors();
  const { data: allActiveRooms, isLoading: isAllActiveRoomsLoading } = useGetAllActiveRooms();
  const { data: patientProfileInfo, isLoading: isPatientProfileInfoLoading } = useGetProfileById(patientSlug);
  const { data: appointmentByPatientSlug, isSuccess: isAppointmentByPatientSlugSuccess, refetch: refetchAppointmentByPatientSlug } = useGetAppointmentByPatientSlug(appointmentSlug);

  const gender = ["MALE", "FEMALE", "OTHERS"];
  const [defaultTime, setDefaultTime] = useState(null);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [value, setValue] = useState("female");
  const [open, setOpen] = useState(false);
  const [addtoggle, setaddtoggle] = useState(false);
  const history = useHistory();
  const [receiptDate, setReceiptDate] = useState();
  const [rotate, setRotate] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [roleData, setRoleData] = useState([]);
  const patientFormApi = useRef();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surgeryDateTime, setSurgeryDateTime] = useState(null);
  const [personName, setPersonName] = useState([]);
  const [id, setId1] = useState("");

  const [isAdmitted, setIsAdmitted] = useState(false);
  const [AdmitId, setAdmitId] = useState("");

  const handleChangeStatus = () => {};

  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");

  const [bmi, setBmi] = useState("");

  const initPatientFormFields = async (data) => {
    patientFormApi.current.setValue("name", data?.name);
    patientFormApi.current.setValue("dob", data?.dob);
    patientFormApi.current.setValue("email", data?.email);
    patientFormApi.current.setValue("allergies", data?.allergies);
    patientFormApi.current.setValue("mobileNumber", data?.mobileNumber);
    patientFormApi.current.setValue("gender", data?.gender ? data?.gender : "");
    patientFormApi.current.setValue("uhid", data?.uhid);
    patientFormApi.current.setValue(
      "lastVisit",
      data?.lastVisit ? moment(data?.lastVisit).format("MMM Do YY") : "N/A"
    );

    patientFormApi.current.setValue("address", data?.address ?? "N/A");
    patientFormApi.current.setValue("height", data?.height);
    setHeight(data?.height);
    patientFormApi.current.setValue("weight", data?.weight);
    setWeight(data?.weight);
    patientFormApi.current.setValue("maritalStatus", data?.maritalStatus);

    let cal = (
      [Number(data?.weight) / Number(data?.height) / Number(data?.height)] *
      10000
    ).toFixed(1);

    await patientFormApi.current.setValue("bmi", `${cal}`);

    patientFormApi.current.setValue("occupation", data?.occupation);
  };

  useEffect(() => {
    if (isNewPatientAdmitForm(urlPathValue) && patientSlug && patientProfileInfo) {
      setProfileData(patientProfileInfo);

      setRoleData(patientProfileInfo?.notes);
      setId1(patientProfileInfo?._id);

      initPatientFormFields(patientProfileInfo);
    }
  }, [urlPathValue, patientSlug, patientProfileInfo]);

  useEffect(() => {
    if (weight && height) {
      let cal = (
        [Number(weight) / Number(height) / Number(height)] * 10000
      ).toFixed(1);
      setBmi(cal);
    } else {
      setBmi("");
      // setInfo('');
    }
  }, [weight, height]);

  const handlePatientFormSubmit = (val) => {
    if (val && profileData) {
      const temp = profileData;
      temp.name = val.name;
      temp.dob = val.dob;
      temp.gender = val.gender;
      temp.email = val.email;
      temp.address = val.address;
      temp.height = val.height;
      temp.weight = val.weight;
      temp.bmi = bmi;
      temp.occupation = val.occupation;
      temp.maritalStatus = val.maritalStatus;
      temp.remarks = val.remarks;
      temp.allergies = val.allergies;

      updatePatient(temp);
    }
  };

  const updatePatient = (data) => {
    setSaved(true);
    //let reqData = data.data;
    data.id = id;

    Api.put(`patient/profile`, data).subscribe(
      () => {
        setSaved(false);
        SHOW_SUCCESS_NOTIFICATION("Updated sucessfully");
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setSaved(false);
      }
    );
  };

  const handleClosee = () => {
    setOpen(false);
  };

  const addAppointmentItemstoggle = () => {
    setaddtoggle(!addtoggle);
    setRotate(!rotate);
    window.location.href = `#!` + Routes.PRESCRIPTION + `?id=${urlPathValue}`;
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const activityitembtn = () => {
    history.push(Routes.APPOINTMENTBILLING);
  };
  const admitedbtnclick = () => {
    history.push(Routes.ADMITED);
  };

  const handleChangee = (event) => {
    setValue(event.target.value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const onSelectPrintingTIme = (timeString) => {
    setDefaultTime(timeString);
    if (receiptDate) {
      let surgdate =
        moment(receiptDate).format("YYYY-MM-DD") +
        " " +
        timeString.format("HH:mm:ss");

      setSurgeryDateTime(surgdate);
    }
  };
  const [dayStartTime, setDayStartTime] = useState(null);

  const [dayStartdate, setdayStartDate] = useState(null);
  const ondayStartTime = (timeString) => {
    setDayStartTime(timeString);

    if (timeString) {
      let daystartDate = timeString.format("HH:mm:ss");

      setdayStartDate(daystartDate);
    }
  };
  const onReceiptDateSelected = (dateInput) => {
    setReceiptDate(dateInput);
    if (defaultTime) {
      // let surgdate = moment(dateInput).format("YYYY-MM-DD") + " " + moment(defaultTime).format("HH:mm:ss");
      let surgdate =
        format(dateInput?.$d, "yyyy-MM-dd") +
        " " +
        moment(defaultTime).format("HH:mm:ss");

      setSurgeryDateTime(surgdate);
    }
  };

  const onexpectedDischarge = (expecteddate) => {
    setPatientData((prevData) => ({
      ...prevData,
      expectedDischarge: expecteddate, // Replace with the desired value for expectedDischarge
    }));
  };

  const [PrintingTime, setPrintingTime] = useState(
    dayjs("2014-08-18T21:11:54")
  );

  const [addPatientData, setPatientData] = useState({
    prov: "",
    chiefComplaints: "",
    hopi: "",
    examination: "",
    allowedFoods: "",
    plan: "",
    pacStatus: "",
    roomNumber: "",
    surgeryType: "",
    anesthesia: "",
    bloodRequirement: "",
    specialRequirements: "",
    expectedDischarge: "",

    otherDoctors: [],
    surgeryDateTime: "",
    patientSlug: "",
    appointmentSlug: "",
    doctorSlug: "",
    status: "",
  });

  if (isAppointmentByPatientSlugSuccess) {
    const patientData = appointmentByPatientSlug[0]["prescription"];
    addPatientData.prov = addPatientData.prov || patientData?.prov;
    addPatientData.chiefComplaints = addPatientData.chiefComplaints || patientData?.chiefComplaints;
    addPatientData.hopi = addPatientData.hopi || patientData?.hopi;
    addPatientData.examination = addPatientData.examination || patientData?.examination;
    addPatientData.plan = addPatientData.plan || patientData?.surgicalPlan;
  }

  useEffect(() => {
    if(isAppointmentByPatientSlugSuccess) {
      if(appointmentByPatientSlug[0]?.isAdmit) {
        setIsAdmitted(true);
      }
    }
  }, [isAppointmentByPatientSlugSuccess, isAdmitted])

  const globalClass = useGlobalStyles();
  const classes = useStyles();

  const handleSlug = (event, value) => {
    setPersonName(value);
    // Create an array to store the selected slugs
    const selectedSlugs = [];

    // Iterate through the selected names
    value.forEach((name) => {
      // Find the corresponding object in slugdata array
      const selectedOption = allDoctorsList.find((option) => option.name === name);

      // If the object exists, push the slug to the selectedSlugs array
      if (selectedOption) {
        selectedSlugs.push(selectedOption.slug);
      }
    });

    // Set the selected slugs in the addPatientData.otherDoctors field
    addPatientData.otherDoctors = selectedSlugs;
  };

  const handleadmitpatient = (event) => {
    const { name, value } = event.target;

    setPatientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // const { urlPathValue } = useParams();

  const findSettingValue = (value, name) => {
    if (timings.includes(value)) {
      return value;
    } else if (value != null) {
      setCustomValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      return "Custom";
    }
    return null;
  };
  const [RoomNumber, setRoomNumber] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);
  const confirmpopup = () => {
    setOpenConfirm(true);
  };

  // useEffect(() => {
  //   if(isAdmitted){
      
  //   }
  // });
  useEffect(() => {
    if(!isNewPatientAdmitForm(urlPathValue)) {
      settimeLoading(true);
      Api.get(`admit/id/${urlPathValue}`).subscribe((resp) => {
        settimeLoading(false);
        let fetchdata = resp.data.data;
        let getalarmdata = resp.data.data.alarmSettings;
        setRoomNumber(resp.data.data?.roomNumber);

        if (!personName.length) {
          if (fetchdata.otherDoctors) {
            fetchdata.otherDoctors.forEach((doctorSlug) => {
              !isAllDoctorsListLoading && allDoctorsList.forEach((options) => {
                if (options.slug === doctorSlug) {
                  personName.push(options.name);
                }
              });
            });
          }
        }
        addPatientData.doctorSlug = fetchdata?.doctorSlug;
        addPatientData.patientSlug = fetchdata?.patientSlug;
        addPatientData.appointmentSlug = fetchdata?.appointmentSlug;
        addPatientData.prov = fetchdata?.prov;
        addPatientData.chiefComplaints = fetchdata?.chiefComplaints;
        addPatientData.hopi = fetchdata?.hopi;
        addPatientData.examination = fetchdata?.examination;
        addPatientData.allowedFoods = fetchdata?.allowedFoods;
        addPatientData.plan = fetchdata?.plan;
        addPatientData.pacStatus = fetchdata?.pacStatus;
        addPatientData.roomNumber = RoomNumber;
        addPatientData.surgeryType = fetchdata?.surgeryType;
        addPatientData.anesthesia = fetchdata?.anesthesia;
        addPatientData.bloodRequirement = fetchdata?.bloodRequirement;
        addPatientData.specialRequirements = fetchdata?.specialRequirements;
        addPatientData.status = fetchdata?.status;

        setReceiptDate(fetchdata?.surgeryDateTime ? dayjs(fetchdata.surgeryDateTime) : null);

        setDefaultTime(fetchdata?.surgeryDateTime ? dayjs(fetchdata.surgeryDateTime) : null);
        setSurgeryDateTime(fetchdata?.surgeryDateTime);

        addPatientData.surgeryDateTime = fetchdata?.surgeryDateTime;

        addPatientData.expectedDischarge = dayjs(fetchdata?.expectedDischarge);
        addPatientData.otherDoctors = fetchdata?.otherDoctors;

        // alarmsettingss

        // addalarmData.dayStart = getalarmdata?.dayStart;

        if (getalarmdata?.dayStart) {
          const parsedTime = dayjs(`1970-01-01T${getalarmdata.dayStart}`);
          setDayStartTime(parsedTime);
        }
        setdayStartDate(getalarmdata?.dayStart);
        addalarmData.alarmSettings = getalarmdata?.alarmSettings;
        addalarmData.medicationAlarm = getalarmdata?.medicationAlarm;
        addalarmData.progressNotes = findSettingValue(
          getalarmdata?.progressNotes,
          "progressNotes"
        );
        addalarmData.vitalsMeasurement = findSettingValue(
          getalarmdata?.vitalsMeasurement,
          "vitalsMeasurement"
        );
        addalarmData.rbsChecking = findSettingValue(
          getalarmdata?.rbsChecking,
          "rbsChecking"
        );
        addalarmData.inputOutputMonitoring = findSettingValue(
          getalarmdata?.inputOutputMonitoring,
          "inputOutputMonitoring"
        );
        addalarmData.passedMotion = findSettingValue(
          getalarmdata?.passedMotion,
          "passedMotion"
        );
        addalarmData.passedFlatus = findSettingValue(
          getalarmdata?.passedFlatus,
          "passedFlatus"
        );
        addalarmData.beddingDone = findSettingValue(
          getalarmdata?.beddingDone,
          "beddingDone"
        );
        addalarmData.oralCareDone = findSettingValue(
          getalarmdata?.oralCareDone,
          "oralCareDone"
        );
        addalarmData.bathingDone = findSettingValue(
          getalarmdata?.bathingDone,
          "bathingDone"
        );

        Api2.get(`/getprofile?id=${fetchdata?.patientSlug}`).subscribe((resp) => {
          let inProfileData = resp.data.data;
          setProfileData(inProfileData);
          let roledata = inProfileData.notes;

          setRoleData(roledata);
          setId1(resp.data.data._id);

          initPatientFormFields(resp.data.data);
        });
      });
    }
  }, [urlPathValue, RoomNumber, allDoctorsList, openConfirm, loading, isAdmitted]);

  const onsaveData = async () => {
    setLoading(true);
    // if (!addPatientData.plan) {
    //   SHOW_ERROR_NOTIFICATION("Please enter plan");
    //   return;
    // }
    function validateAndShowError(value, errorMessage) {
      if (!value) {
        SHOW_ERROR_NOTIFICATION(errorMessage);
        setLoading(false);
        return false;
      }
      return true;
    }

    // Usage
    if (
      !validateAndShowError(
        addPatientData.surgeryType,
        "Please choose surgery Type"
      )
    )
      return;
    if (
      !validateAndShowError(
        addPatientData.anesthesia,
        "Please enter anesthesia"
      )
    )
      return;
    if (
      !validateAndShowError(
        addPatientData.bloodRequirement,
        "Please enter bloodRequirement"
      )
    )
      return;
    if (
      !validateAndShowError(
        addPatientData.specialRequirements,
        "Please enter specialRequirements"
      )
    )
      return;
    if (
      !validateAndShowError(addPatientData.pacStatus, "Please choose pacstatus")
    )
      return;
    // if (!validateAndShowError(addPatientData.roomNumber, "Please choose roomNumber")) return;
    if (
      !validateAndShowError(
        addPatientData.expectedDischarge,
        "Please enter expected Discharge"
      )
    )
      return;
    addPatientData.surgeryDateTime = surgeryDateTime;

    // if (!validateAndShowError(surgeryDateTime, "Please enter date and time"))
    //   return;
    let admitID;
    if (isAdmitted) {
      admitID = AdmitId;
    } else {
      admitID = urlPathValue;
    }

    await Api.put(`/admit/edit/${admitID}`, addPatientData).subscribe(
      (response) => {
        if (response.data.status === true) {
          SHOW_SUCCESS_NOTIFICATION(response.data.message);
        }

        setLoading(false);
        handleClosee();
      }
    );
  };
  const [addalarmData, setAlarmData] = useState({
    alarmSettings: "",
    medicationAlarm: "",
    progressNotes: "",
    vitalsMeasurement: "",
    rbsChecking: "",
    inputOutputMonitoring: "",
    passedMotion: "",
    passedFlatus: "",
    beddingDone: "",
    oralCareDone: "",
    bathingDone: "",
  });
  // const handleAlarmpatient = (event) => {
  //     const { name, value } = event.target;

  //     if (value != "Custom") {
  //         setAlarmData((prevData) => ({
  //             ...prevData,
  //             [name]: value
  //         }));
  //     }
  // };
  const handleAlarmpatient = (event) => {
    const { name, value } = event.target;

    if (value !== "Custom") {
      // Check if the clicked button is already selected
      if (addalarmData[name] === value) {
        // If it is selected, set the value to null or an empty string to unselect it
        setAlarmData((prevData) => ({
          ...prevData,
          [name]: null, // or ''
        }));
      } else {
        // If it is not selected, update the value
        setAlarmData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (value === "Custom") {
      setAlarmData((prevData) => ({
        ...prevData,
        [name]: null, // or ''
      }));
    }
  };

  const sections = [
    { name: "alarmSettings", label: "Alarm Settings", type: "1" },
    { name: "medicationAlarm", label: "Medication Alarm", type: "2" },
    { name: "progressNotes", label: "Progress Notes", type: "3" },
    { name: "vitalsMeasurement", label: "Vitals Measurement", type: "3" },
    { name: "rbsChecking", label: "RBS Checking", type: "3" },
    {
      name: "inputOutputMonitoring",
      label: "Input Output Monitoring",
      type: "3",
    },
    { name: "passedMotion", label: "Passed Motion", type: "3" },
    { name: "passedFlatus", label: "Passed Flatus", type: "3" },
    { name: "beddingDone", label: "Bedding Done", type: "3" },
    { name: "oralCareDone", label: "Oral Care Done", type: "3" },
    { name: "bathingDone", label: "Bathing Done", type: "3" },
  ];

  const [customValues, setCustomValues] = useState({});

  const handleCustomInputChange = (event) => {
    const { name, value } = event.target;

    setCustomValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const [selectedName, setselectedName] = useState("");
  const [open1, setOpen1] = useState(false);

  const addhandleOpen = (section) => {
    let name = section.name;
    // addalarmData[name] = "";
    setselectedName(name);

    setOpen1(true);
  };

  const onSaveCustomChange = () => {
    setAlarmData((prevData) => ({
      ...prevData,
      [selectedName]: "Custom",
    }));
    setOpen1(false);
  };

  const onConfirmationOfDischarged = () => {
    Api.put(`admit/status/${urlPathValue}/${"Discharged"}`).subscribe((resp) => {
      // SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
      SHOW_SUCCESS_NOTIFICATION("Updated Successfully");
      setOpenConfirm(false);
      // window.open(`#!` + `${Routes.APPOINTMENT}`, '_blank');
      history.push(Routes.APPOINTMENT);
    });
  };

  const handleClose = () => {
    setOpen1(false);
    setOpenConfirm(false);
  };

  const alarmdata = {
    dayStart: dayStartdate,
    alarmSettings: addalarmData.alarmSettings,
    medicationAlarm: addalarmData.medicationAlarm,
    progressNotes: addalarmData.progressNotes,
    vitalsMeasurement: addalarmData.vitalsMeasurement,
    rbsChecking: addalarmData.rbsChecking,
    inputOutputMonitoring: addalarmData.inputOutputMonitoring,
    passedMotion: addalarmData.passedMotion,
    passedFlatus: addalarmData.passedFlatus,
    beddingDone: addalarmData.beddingDone,
    oralCareDone: addalarmData.oralCareDone,
    bathingDone: addalarmData.bathingDone,
  };

  const setSettingValue = (value, name) => {
    if (value === "Custom") {
      return customValues[name];
    } else {
      return value;
    }
  };
  const [isSavedata, setisSaveData] = useState(false);
  const handleSaveData = () => {
    if (appointmentSlug && isAppointmentByPatientSlugSuccess) {
      refetchAppointmentByPatientSlug();
      if (appointmentByPatientSlug[0]?.isAdmit === undefined || appointmentByPatientSlug[0]?.isAdmit === false) {
        SHOW_ERROR_NOTIFICATION("Patient is not admitted");
        return;
      }
    }
    alarmdata.dayStart = dayStartdate;
    alarmdata.progressNotes = setSettingValue(
      alarmdata?.progressNotes,
      "progressNotes"
    );
    alarmdata.vitalsMeasurement = setSettingValue(
      alarmdata?.vitalsMeasurement,
      "vitalsMeasurement"
    );
    alarmdata.rbsChecking = setSettingValue(
      alarmdata?.rbsChecking,
      "rbsChecking"
    );
    alarmdata.inputOutputMonitoring = setSettingValue(
      alarmdata?.inputOutputMonitoring,
      "inputOutputMonitoring"
    );
    alarmdata.passedMotion = setSettingValue(
      alarmdata?.passedMotion,
      "passedMotion"
    );
    alarmdata.passedFlatus = setSettingValue(
      alarmdata?.passedFlatus,
      "passedFlatus"
    );
    alarmdata.beddingDone = setSettingValue(
      alarmdata?.beddingDone,
      "beddingDone"
    );
    alarmdata.oralCareDone = setSettingValue(
      alarmdata?.oralCareDone,
      "oralCareDone"
    );
    alarmdata.bathingDone = setSettingValue(
      alarmdata?.bathingDone,
      "bathingDone"
    );
    setisSaveData(true);
    let admitID;
    if (isAdmitted) {
      admitID = AdmitId;
    } else {
      admitID = urlPathValue;
    }
    Api.put(`admit/alarm-settings/${admitID}`, alarmdata).subscribe((resp) => {
      setisSaveData(false);
      SHOW_SUCCESS_NOTIFICATION("Updated Successfully");
    });
  };

  const timings = ["Once", "Daily", "12 Hours", "8 Hours", "6 Hours", "Custom"];
  const AlarmSettings = ["Active", "Deactivate"];
  const AlarmSettings1 = ["Active", "Inactive"];

  const [timeLoading, settimeLoading] = useState(false);

  const admitPatientMutation = useAdmitPatient();
  const handleAdmitNewPatient = async () => {
  addPatientData.surgeryDateTime = surgeryDateTime;
  addPatientData.doctorSlug = doctorSlug;
  addPatientData.patientSlug = patientSlug;
  addPatientData.appointmentSlug = appointmentSlug;
  addPatientData.medications = [];
    try {
      if (!isAdmitNewPatientFieldsValid(addPatientData)) {
        return;
      }
      await admitPatientMutation.mutateAsync(addPatientData);
      setLoading(false);
      handleClosee();
      setIsAdmitted(true);
      refetchAppointmentByPatientSlug();
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAdmitData = async () => {
      if (isAdmitted) {
        const searchPayload = {
          "size": 20,
          "page": 0,
          "filterModel": {
            "doctorSlug": {
              "type": "equals",
              "value": doctorSlug
            },
            "patientSlug": {
              "type": "equals",
              "value": patientSlug
            },
          }
        };
  
        try {
          const searchData = await SearchAdmit(searchPayload);
          const searchDataContent = searchData?.data?.content[0];
          let getalarmdata = searchDataContent.alarmSettings;
          addPatientData.doctorSlug = searchDataContent?.doctorSlug;
          addPatientData.patientSlug = searchDataContent?.patientSlug;
          addPatientData.appointmentSlug = searchDataContent?.appointmentSlug;
          addPatientData.prov = searchDataContent?.prov;
          addPatientData.chiefComplaints = searchDataContent?.chiefComplaints;
          addPatientData.hopi = searchDataContent?.hopi;
          addPatientData.examination = searchDataContent?.examination;
          addPatientData.allowedFoods = searchDataContent?.allowedFoods;
          addPatientData.plan = searchDataContent?.plan;
          addPatientData.pacStatus = searchDataContent?.pacStatus;
          addPatientData.roomNumber = searchDataContent?.roomNumber;
          addPatientData.surgeryType = searchDataContent?.surgeryType;
          addPatientData.anesthesia = searchDataContent?.anesthesia;
          addPatientData.bloodRequirement = searchDataContent?.bloodRequirement;
          addPatientData.specialRequirements = searchDataContent?.specialRequirements;
          addPatientData.status = searchDataContent?.status;

          setReceiptDate(searchDataContent?.surgeryDateTime ? dayjs(searchDataContent.surgeryDateTime) : null);

          setDefaultTime(searchDataContent?.surgeryDateTime ? dayjs(searchDataContent.surgeryDateTime) : null);
          setSurgeryDateTime(searchDataContent?.surgeryDateTime);

          addPatientData.surgeryDateTime = searchDataContent?.surgeryDateTime;

          addPatientData.expectedDischarge = dayjs(searchDataContent?.expectedDischarge);
          addPatientData.otherDoctors = searchDataContent?.otherDoctors;

          if (getalarmdata?.dayStart) {
            const parsedTime = dayjs(`1970-01-01T${getalarmdata.dayStart}`);
            setDayStartTime(parsedTime);
          }
          setdayStartDate(getalarmdata?.dayStart);
          addalarmData.alarmSettings = getalarmdata?.alarmSettings;
          addalarmData.medicationAlarm = getalarmdata?.medicationAlarm;
          addalarmData.progressNotes = findSettingValue(
            getalarmdata?.progressNotes,
            "progressNotes"
          );
          addalarmData.vitalsMeasurement = findSettingValue(
            getalarmdata?.vitalsMeasurement,
            "vitalsMeasurement"
          );
          addalarmData.rbsChecking = findSettingValue(
            getalarmdata?.rbsChecking,
            "rbsChecking"
          );
          addalarmData.inputOutputMonitoring = findSettingValue(
            getalarmdata?.inputOutputMonitoring,
            "inputOutputMonitoring"
          );
          addalarmData.passedMotion = findSettingValue(
            getalarmdata?.passedMotion,
            "passedMotion"
          );
          addalarmData.passedFlatus = findSettingValue(
            getalarmdata?.passedFlatus,
            "passedFlatus"
          );
          addalarmData.beddingDone = findSettingValue(
            getalarmdata?.beddingDone,
            "beddingDone"
          );
          addalarmData.oralCareDone = findSettingValue(
            getalarmdata?.oralCareDone,
            "oralCareDone"
          );
          addalarmData.bathingDone = findSettingValue(
            getalarmdata?.bathingDone,
            "bathingDone"
          );

          Api2.get(`/getprofile?id=${patientSlug}`).subscribe((resp) => {
            let inProfileData = resp.data.data;
            setProfileData(inProfileData);
            let roledata = inProfileData.notes;
  
            setRoleData(roledata);
            setId1(resp.data.data._id);
  
            initPatientFormFields(resp.data.data);
          });

          setAdmitId(searchDataContent?.id);
          
        } catch (error) {
          console.error("Error fetching admit data:", error);
        }
      }
    };
  
    fetchAdmitData();
  }, [isAdmitted]);

  return (
    <>
      {profileData === null ? (
        <Loader />
      ) : (
        <Box className={globalClass.container} minHeight="90vh" mx={4}>
          {/* <Typography
            variant="h5"
            className={classes.title}
            style={{ marginBottom: "10px" }}
          >
            {isNewPatientAdmitForm(urlPathValue) ? "Admit Patient" : "Admitted"}
          </Typography> */}
          <Header showBackBtn title={isNewPatientAdmitForm(urlPathValue) ? "Admit Patient" : "Admitted"} menu={true} />

          <Grid container direction="row" spacing={8}>
            {/* COLUMN 1: ADMIT FORM */}
            <Grid item xs={12} md={12} lg={6}>
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "14px",
                  padding: "20px",
                }}
              >
                <div style={{ marginBottom: "5px" }}>
                  <div
                    style={{
                      backgroundColor: "#EEF6FF",
                      // margin: "15px 25px",
                      padding: "20px 10px",
                      borderRadius: "20px",
                    }}
                  >
                    <div style={{ padding: "10px" }}>
                      <label style={{ fontSize: "14px", margin: "10px" }}>
                        Provisional Diagnosis
                      </label>{" "}
                      <br />
                      <input
                        required
                        value={addPatientData.prov}
                        onChange={handleadmitpatient}
                        name="prov"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          padding: "20px 60px",
                          padding: "10px 20px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Provisional Diagnosis"
                      />
                    </div>
                    <div style={{ padding: "10px" }}>
                      <label style={{ fontSize: "14px", margin: "10px" }}>
                        Chief complaints
                      </label>{" "}
                      <br />
                      <input
                        required
                        value={addPatientData.chiefComplaints}
                        onChange={handleadmitpatient}
                        name="chiefComplaints"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          padding: "20px 60px",
                          padding: "10px 20px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Chief complaints"
                      />
                    </div>
                    <div style={{ padding: "10px" }}>
                      <label style={{ fontSize: "14px", margin: "10px" }}>
                        HOPI
                      </label>{" "}
                      <br />
                      <input
                        required
                        value={addPatientData.hopi}
                        onChange={handleadmitpatient}
                        name="hopi"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          padding: "20px 60px",
                          padding: "10px 20px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="HOPI"
                      />
                    </div>

                    <div style={{ padding: "10px" }}>
                      <label style={{ fontSize: "14px", margin: "10px" }}>
                        Examination
                      </label>{" "}
                      <br />
                      <input
                        required
                        value={addPatientData.examination}
                        onChange={handleadmitpatient}
                        name="examination"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          padding: "20px 60px",
                          padding: "10px 20px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Examination"
                      />
                    </div>
                    <div style={{ padding: "10px" }}>
                      <label style={{ fontSize: "14px", margin: "10px" }}>
                        Allowed Foods
                      </label>{" "}
                      <br />
                      <input
                        required
                        value={addPatientData.allowedFoods}
                        onChange={handleadmitpatient}
                        name="allowedFoods"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          padding: "20px 60px",
                          padding: "10px 20px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Allowed foods"
                      />
                    </div>
                  </div>
                </div>

                <Grid item xs={4}>
                  <label>Plan</label>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    className={globalClass.roundWhiteBg}
                    style={{ width: "100%" }}
                    value={addPatientData.plan}
                    onChange={handleadmitpatient}
                    containerClassName={globalClass.inputContainer}
                    required
                    // field="name"
                    name="plan"
                    type="text"
                    // label="Patient Name"
                    validateOnBlur
                    validateOnChange
                  />
                </Grid>

                <Grid item xs={4}>
                  {/* <FormLabel>Surgery Type:</FormLabel> */}
                  <label>Surgery Type:</label>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    // defaultValue="elective"
                    // name="controlled-radio-buttons-group"
                    name="surgeryType"
                    value={addPatientData.surgeryType}
                    onChange={handleadmitpatient}
                    sx={{ my: 1 }}
                    row
                  >
                    <FormControlLabel
                      value="elective"
                      control={<Radio />}
                      label="Elective"
                    />
                    <FormControlLabel
                      value="emergency"
                      control={<Radio />}
                      label="Emergency"
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={4}>
                  <label>Anesthesia</label>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    className={globalClass.roundWhiteBg}
                    style={{ width: "100%" }}
                    value={addPatientData.anesthesia}
                    containerClassName={globalClass.inputContainer}
                    onChange={handleadmitpatient}
                    required
                    // field="name"
                    name="anesthesia"
                    type="text"
                    // label="Patient Name"
                    validateOnBlur
                    validateOnChange
                  />
                </Grid>

                {/* 4 */}
                <Grid item xs={4}>
                  <label>Blood Requirement</label>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    style={{ width: "100%" }}
                    className={globalClass.roundWhiteBg}
                    containerClassName={globalClass.inputContainer}
                    value={addPatientData.bloodRequirement}
                    onChange={handleadmitpatient}
                    required
                    // field="name"
                    name="bloodRequirement"
                    type="text"
                    // label="Patient Name"
                    validateOnBlur
                    validateOnChange
                  />
                </Grid>

                {/* 5 */}
                <Grid item xs={4}>
                  <label>Special Requirements</label>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    className={globalClass.roundWhiteBg}
                    containerClassName={globalClass.inputContainer}
                    onChange={handleadmitpatient}
                    value={addPatientData.specialRequirements}
                    style={{ width: "100%" }}
                    required
                    // field="name"
                    name="specialRequirements"
                    type="text"
                    // label="Patient Name"
                    validateOnBlur
                    validateOnChange
                  />
                </Grid>

                {/* allowedfoods */}

                {/* <Grid item xs={4}>
                                    <label>Allowed Foods</label>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput
                                        className={globalClass.roundWhiteBg}
                                        containerClassName={globalClass.inputContainer}
                                        onChange={handleadmitpatient}
                                        value={addPatientData.allowedFoods}
                                        style={{ width: "100%" }}

                                        required
                                        // field="name"
                                        name="allowedFoods"
                                        type="text"
                                        // label="Patient Name"
                                        validateOnBlur
                                        validateOnChange
                                    />
                                </Grid> */}

                {/* <br />
                                <br /> */}
                <br />

                <Grid item xs={4}>
                  <label>Surgery Date&Time</label>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Surgery Date (DD/MM/YYYY)"
                        value={receiptDate}
                        name="surgeryDateTime"
                        onChange={(newValue) => {
                          onReceiptDateSelected(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" error={false}/>
                        )}
                        inputFormat="DD/MM/YYYY"
                        className={globalClass.roundWhiteBg}
                        format="DD/MM/YYYY"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TimePicker
                        label="Surgery Time"
                        value={defaultTime}
                        name="surgeryDateTime"
                        onChange={(newValue) => {
                          onSelectPrintingTIme(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={false}
                            variant="outlined"
                          />
                        )}
                        className={globalClass.roundWhiteBg}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <br />
                                <br />
                                <br />
                                <br /> */}
                <br />
                {/* expectedDischarge */}
                <Grid item xs={4}>
                  <label>Expected Discharge</label>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Expected Discharge (DD/MM/YYYY)"
                        value={addPatientData.expectedDischarge || null}
                        name="expectedDischarge"
                        onChange={(newValue) => {
                          onexpectedDischarge(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        inputFormat="DD/MM/YYYY"
                        className={globalClass.roundWhiteBg}
                        format="DD/MM/YYYY"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <br />
                                <br />
                                <br /> */}
                <br />

                {/* otherDoctors */}
                {/* <Grid container spacing={4}> */}
                <Grid item md={3}>
                  <label>Other Doctors</label>
                </Grid>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={
                    allDoctorsList && allDoctorsList.length > 0
                      ? allDoctorsList.map((option) => option.name)
                      : []
                  }
                  getOptionLabel={(option) => option}
                  className={globalClass.roundWhiteBg}
                  onChange={handleSlug}
                  value={personName}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={"Select Doctor"}
                        variant="outlined"
                      />
                    );
                  }}
                  style={{ width: "100%" }}
                />

                <Grid item xs={4}>
                  <FormLabel>PAC Status</FormLabel>
                </Grid>
                <Grid></Grid>
                <Grid item xs={8}>
                  <RadioGroup
                    // defaultValue="fit"
                    name="pacStatus"
                    value={addPatientData.pacStatus}
                    onChange={handleadmitpatient}
                    sx={{ my: 1 }}
                    row
                  >
                    <FormControlLabel
                      value="fit"
                      control={<Radio />}
                      label="Fit"
                    />
                    <FormControlLabel
                      value="unfit"
                      control={<Radio />}
                      label="Unfit"
                    />
                    <FormControlLabel
                      value="pending"
                      control={<Radio />}
                      label="Pending"
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={4}>
                  <FormLabel>Room No.</FormLabel>
                </Grid>

                <Grid item xs={8}>
                  {!isNewPatientAdmitForm(urlPathValue) || isAdmitted ? (
                    <Grid item xs={4}>
                      <Typography variant="h4">{addPatientData.roomNumber}</Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      {allActiveRooms && allActiveRooms.length > 0 ? (
                        <Grid container spacing={1}>
                          {allActiveRooms.map((room) => (
                            <Grid item key={room.id}>
                              <Chip
                                name="roomNumber"
                                label={room.roomNumber}
                                onClick={() => handleadmitpatient({target: {value: room.roomNumber, name: "roomNumber"}})}
                                color={addPatientData.roomNumber === room.roomNumber ? "primary" : "default"}
                                variant={addPatientData.roomNumber === room.roomNumber ? "filled" : "outlined"}
                                style={{
                                  backgroundColor: addPatientData.roomNumber === room.roomNumber ? "#445b7a" : undefined,
                                  color: addPatientData.roomNumber === room.roomNumber ? "#ffffff" : undefined,
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography variant="h4">
                          No rooms available
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>

                {addPatientData.status === "Discharged" ? (
                  ""
                ) : (
                  <>
                    <Box
                      style={{ marginTop: "10px" }}
                      ml={4}
                      display={{ xs: "none", sm: "inline-block" }}
                      // className={globalClass.largeBtnContainer}
                    >
                      {loading ? (
                        <Button variant="contained" color="primary" disabled>
                          <CircularProgress size={20} />
                          Loading
                        </Button>
                      ) : (
                        <Button
                          style={{ padding: "6px 9px" }}
                          className={classes.printBtnContainer}
                          variant="contained"
                          color="primary"
                          onClick={
                            (!isNewPatientAdmitForm(urlPathValue) || isAdmitted)
                              ? onsaveData
                              : handleAdmitNewPatient
                          }
                        >
                          {(!isNewPatientAdmitForm(urlPathValue))
                            ? "Update Patient"
                            : isAdmitted ? "Update Admission" : "Admit"}
                        </Button>
                      )}
                    </Box>

                    {!isNewPatientAdmitForm(urlPathValue) && (
                      <Box
                        style={{ marginTop: "10px" }}
                        ml={4}
                        display={{ xs: "none", sm: "inline-block" }}
                      >
                        <Button
                          onClick={confirmpopup}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Discharged
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </div>
            </Grid>

            {/* COLUMN 2: [ROW 1: PROFILE, ROW 2: ALARM SETTING] */}
            <Grid item xs={12} md={12} lg={6}>
              <Grid container direction="row">
                <Grid item>
                  <Box
                    flexGrow={1}
                    // display={{ xs: "none", sm: "block" }}
                    className={clsx(
                      classes["prescription-details"],
                      globalClass.roundWhiteBg
                    )}
                  >
                    {profileData?.profilePic ? (
                      <Box>
                        <img
                          src={profileData?.profilePic}
                          style={{ width: "100px", borderRadius: "50%" }}
                          alt="Profile Pic"
                        />
                      </Box>
                    ) : (
                      <Box>
                        <img
                          src="/profilePic.png"
                          style={{ width: "100px", borderRadius: "50%" }}
                          alt="Profile Pic"
                        />
                      </Box>
                    )}

                    <br />

                    <br />

                    <Form
                      apiRef={patientFormApi}
                      onSubmit={handlePatientFormSubmit}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Name"
                            field="name"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <CustomDateInput
                            containerClassName={"mr"}
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            openTo="year"
                            inputVariant="outlined"
                            field="dob"
                            label=" DOB"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <SelectInput
                            required
                            containerClassName={"mr"}
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            options={gender}
                            field="gender"
                            placeholder="Gender"
                            label="Gender"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            disabled
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Mobile Number"
                            field="mobileNumber"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Email"
                            field="email"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="UHID"
                            field="uhid"
                            disabled
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Last Visit"
                            field="lastVisit"
                            disabled
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            style={{ width: "100%" }}
                            className={globalClass.roundWhiteBg}
                            label="Address"
                            field="address"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            style={{ width: "100%" }}
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Creator role"
                            field="creatorRole"
                            validateOnBlur
                            value={roleData && (roleData.userRole || "")}
                            disabled
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            style={{ width: "100%" }}
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Creator Name"
                            field="creatorName"
                            validateOnBlur
                            disabled
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Occupation"
                            field="occupation"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Height in cm"
                            field="height"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="Weight in kg"
                            field="weight"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            label="BMI"
                            field="bmi"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <SelectInput
                            required
                            containerClassName={"mr"}
                            className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                            options={["Married", "Unmarried"]}
                            field="maritalStatus"
                            placeholder="Marital Status"
                            label="Marital Status"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={`${classes["prescription-details"]}__column`}
                        >
                          <TextInput
                            style={{ width: "100%" }}
                            className={globalClass.roundWhiteBg}
                            label="Allergies"
                            field="allergies"
                            validateOnBlur
                            validateOnChange
                          />
                        </Grid>
                      </Grid>
                      {addPatientData.status === "Discharged" ? (
                        ""
                      ) : (
                        <>
                          <Box my="1rem">
                            {saved ? (
                              <Button
                                variant="contained"
                                color="primary"
                                disabled
                              >
                                <CircularProgress size={20} />
                                Loading
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                Update
                              </Button>
                            )}
                          </Box>
                        </>
                      )}
                    </Form>
                    {/* remaining portion of admit patient */}
                  </Box>
                </Grid>
                <Grid item>
                  {timeLoading ? (
                    <Loader />
                  ) : (
                    <Box
                      className={clsx(
                        classes["prescription-qr"],
                        globalClass.roundWhiteBg
                      )}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Typography variant="body1">Day Start</Typography>
                        </Grid>
                        <Grid item xs={10} container justify="flex-end">
                          <TimePicker
                            label="Day Start"
                            value={dayStartTime || null}
                            name="dayStart"
                            onChange={(newValue) => {
                              ondayStartTime(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={false}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {sections.map((section, index) => {
                        if (section.type === "1") {
                          return (
                            <Grid container spacing={2}>
                              <Grid item xs={2}>
                                <Typography variant="body1">
                                  {section.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={10} container justify="flex-end">
                                <ThemeProvider theme={myTheme}>
                                  <ToggleButtonGroup
                                    name={section.name}
                                    value={addalarmData[section.name]}
                                    exclusive
                                    onChange={handleAlarmpatient}
                                    aria-label="text alignment"
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {AlarmSettings.map((item, index) => {
                                      return (
                                        <ToggleButton
                                          name={section.name}
                                          key={index}
                                          value={item}
                                          aria-label="centered"
                                          size="small"
                                          style={{
                                            marginRight: "8px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {/* {item.roomNumber} */}
                                          {item}
                                        </ToggleButton>
                                      );
                                    })}
                                  </ToggleButtonGroup>
                                </ThemeProvider>
                              </Grid>
                            </Grid>
                          );
                        }

                        if (section.type === "2") {
                          return (
                            <Grid container spacing={2}>
                              <Grid item xs={2}>
                                <Typography variant="body1">
                                  {section.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={10} container justify="flex-end">
                                <ThemeProvider theme={myTheme}>
                                  <ToggleButtonGroup
                                    name={section.name}
                                    value={addalarmData[section.name]}
                                    exclusive
                                    onChange={handleAlarmpatient}
                                    aria-label="text alignment"
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {AlarmSettings1.map((item, index) => {
                                      return (
                                        <ToggleButton
                                          name={section.name}
                                          key={index}
                                          value={item}
                                          aria-label="centered"
                                          size="small"
                                          style={{
                                            marginRight: "8px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {/* {item.roomNumber} */}
                                          {item}
                                        </ToggleButton>
                                      );
                                    })}
                                  </ToggleButtonGroup>
                                </ThemeProvider>
                              </Grid>
                            </Grid>
                          );
                        }

                        if (section.type === "3") {
                          return (
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              key={index}
                            >
                              <Grid item xs={2}>
                                <Typography variant="body1">
                                  {section.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={10} container justify="flex-end">
                                <ThemeProvider theme={myTheme}>
                                  <ToggleButtonGroup
                                    name={section.name}
                                    value={addalarmData[section.name]}
                                    exclusive
                                    onChange={handleAlarmpatient}
                                    aria-label="text alignment"
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {timings.map((item, index) => {
                                      //let value =
                                      if (item === "Custom") {
                                        return (
                                          <ToggleButton
                                            onClick={() =>
                                              addhandleOpen(section)
                                            }
                                            name={section.name}
                                            key={index}
                                            value={item}
                                            aria-label="centered"
                                            size="small"
                                            style={{
                                              marginRight: "8px",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {item}
                                            {/* {customValues[section.name] || item} */}
                                            {/* {customValues[section.name]} */}
                                          </ToggleButton>
                                        );
                                      } else {
                                        return (
                                          <ToggleButton
                                            name={section.name}
                                            key={index}
                                            value={item}
                                            aria-label="centered"
                                            size="small"
                                            style={{
                                              marginRight: "8px",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {item}
                                          </ToggleButton>
                                        );
                                      }
                                    })}
                                  </ToggleButtonGroup>
                                </ThemeProvider>
                              </Grid>
                            </Grid>
                          );
                        }
                      })}

                      <Grid container spacing={2}>
                        {addPatientData.status === "Discharged" ? (
                          ""
                        ) : (
                          <>
                            {isSavedata ? (
                              <Button
                                variant="contained"
                                color="primary"
                                disabled
                              >
                                <CircularProgress size={20} />
                                Loading
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveData}
                              >
                                Save
                              </Button>
                            )}
                          </>
                        )}
                      </Grid>
                      {/* custom time change */}
                      {open1 && (
                        <Modal
                          open={open1}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            className={classes.modalStyle_1}
                            style={{ background: "white" }}
                          >
                            {/* <Typography variant="h4" component="h2">Update Activity Registry</Typography> */}
                            {/* <CloseIcon onClick={handleClose} style={{ float: 'right', marginBottom: "7px" }} /> */}

                            <Form className={classes.form}>
                              <Box className={classes.staffDetailsForm}>
                                <Box mb="0.5rem">
                                  <Typography
                                    variant="h6"
                                    className={classes.prescriptionTitle}
                                  >
                                    Custom time
                                  </Typography>
                                  <TextInput
                                    className={classes.style_1}
                                    field="time"
                                    type="number"
                                    label="time"
                                    validateOnBlur
                                    validateOnChange
                                    // name='time'
                                    // value={times}
                                    name={selectedName}
                                    value={customValues[selectedName] || ""}
                                    onChange={handleCustomInputChange}
                                    onKeyDown={(e) => {
                                      if (e.key === "." || e.key === "-") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box
                                mx=".4rem"
                                my={12}
                                className={classes.submitBtn}
                              >
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.btn}
                                  onClick={onSaveCustomChange}
                                  disabled={!customValues[selectedName]}
                                >
                                  {/* {lodingStatus.logoLodingStatus ? (<Loader width="200px" height="20px" color="white" />) : ("Save")} */}
                                  Save
                                </Button>
                                &nbsp;
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.btn}
                                  onClick={handleClose}
                                >
                                  {/* {lodingStatus.logoLodingStatus ? (<Loader width="200px" height="20px" color="white" />) : ("Save")} */}
                                  Cancel
                                </Button>
                              </Box>
                              {/* <Box mx='.4rem' my={12} className={classes.submitBtn}>
                                         
                                          
                                        </Box> */}
                            </Form>
                          </Box>
                        </Modal>
                      )}

                      {/* modal for confirmation for discharge */}
                      {openConfirm && (
                        <Modal
                          open={openConfirm}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            className={classes.modalStyle_1}
                            style={{ background: "white", height: "30%" }}
                          >
                            {/* <Typography variant="h4" component="h2">Update Activity Registry</Typography> */}
                            {/* <CloseIcon onClick={handleClose} style={{ float: 'right', marginBottom: "7px" }} /> */}

                            <Form className={classes.form}>
                              <Box className={classes.staffDetailsForm}>
                                <Box mb="0.5rem">
                                  <Typography
                                    variant="h6"
                                    className={classes.prescriptionTitle}
                                  >
                                    Are you Sure to discharge this patient ?
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                mx=".4rem"
                                my={12}
                                className={classes.submitBtn}
                              >
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.btn}
                                  onClick={onConfirmationOfDischarged}
                                  // disabled={!customValues[selectedName]}
                                >
                                  {/* {lodingStatus.logoLodingStatus ? (<Loader width="200px" height="20px" color="white" />) : ("Save")} */}
                                  YES
                                </Button>
                                &nbsp;
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.btn}
                                  onClick={handleClose}
                                >
                                  {/* {lodingStatus.logoLodingStatus ? (<Loader width="200px" height="20px" color="white" />) : ("Save")} */}
                                  NO
                                </Button>
                              </Box>
                              {/* <Box mx='.4rem' my={12} className={classes.submitBtn}>
                                         
                                          
                                        </Box> */}
                            </Form>
                          </Box>
                        </Modal>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {!isNewPatientAdmitForm(urlPathValue) && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                margin: "2px",
                bottom: " 40px",
                marginTop: "10px",
              }}
            >
              {/* <AddCircleIcon onClick={addAppointmentItemstoggle} style={{ fontSize: "45px", transform: rotate ? "rotate(180deg)" : "none", transition: "transform 0.3s ease", }} /> */}
              <AddCircleOutline
                onClick={addAppointmentItemstoggle}
                style={{
                  fontSize: "45px",
                  transform: rotate ? "rotate(180deg)" : "none",
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
          )}
        </Box>
      )}
    </>
  );
};
export default AdmitedProfile;

const isNewPatientAdmitForm = (pathValue) => {
  return pathValue === "new";
}

const isAdmitNewPatientFieldsValid = (admitData) => {
  if (!admitData.plan) {
    SHOW_ERROR_NOTIFICATION("Please enter Plan");
    return false;
  }
  if (!admitData.surgeryType) {
    SHOW_ERROR_NOTIFICATION("Please choose Surgery Type");
    return false;
  }
  if (!admitData.anesthesia) {
    SHOW_ERROR_NOTIFICATION("Please enter Anesthesia");
    return false;
  }
  if (!admitData.bloodRequirement) {
    SHOW_ERROR_NOTIFICATION("Please enter Blood Requirement");
    return false;
  }
  if (!admitData.specialRequirements) {
    SHOW_ERROR_NOTIFICATION("Please enter Special Requirements");
    return false;
  }
  if (!admitData.expectedDischarge) {
    SHOW_ERROR_NOTIFICATION("Please enter Expected Discharge");
    return false;
  }
  if (!admitData.pacStatus) {
    SHOW_ERROR_NOTIFICATION("Please choose PAC Status");
    return false;
  }
  if (!admitData.roomNumber) {
    SHOW_ERROR_NOTIFICATION("Please choose Room Number");
    return false;
  }
  return true;
}
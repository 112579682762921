import React from 'react';
import { Box, Typography, Checkbox } from "@material-ui/core";

const AdmitDataItem = ({ admitData, isChecked, onCheckboxChange, isPrinting = false }) => {
    if (!admitData) return null;

    const handleChange = (event) => {
        onCheckboxChange(admitData.id, event.target.checked);
    };

    const formatDateTime = (epochTimestamp) => {
        const date = new Date(epochTimestamp);
        return `Date: ${date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}, Time: ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
    };

    return (
        <>
            <Box display="flex" alignItems="flex-start" mt={2}>
                {!isPrinting && <Checkbox 
                    style={{paddingTop: "0"}}
                    checked={isChecked}
                    onChange={handleChange}
                />}
                <Box flexGrow={1} p={2} border={1} borderColor="grey.300" borderRadius={4}>
                    <Typography variant="h6" gutterBottom>Admit Data</Typography>
                    <Typography><strong>Special Requirements:</strong> {admitData.specialRequirements || 'N/A'}</Typography>
                    <Typography><strong>Surgery Date/Time:</strong> {admitData.surgeryDateTime || 'N/A'}</Typography>
                    <Typography><strong>Surgery Type:</strong> {admitData.surgeryType || 'N/A'}</Typography>
                    <Typography><strong>Provisional Diagnosis:</strong> {admitData.prov || 'N/A'}</Typography>
                    <Typography><strong>PAC Status:</strong> {admitData.pacStatus || 'N/A'}</Typography>
                    <Typography><strong>History of Present Illness:</strong> {admitData.hopi || 'N/A'}</Typography>
                    <Typography><strong>Examination:</strong> {admitData.examination || 'N/A'}</Typography>
                    <Typography><strong>Chief Complaints:</strong> {admitData.chiefComplaints || 'N/A'}</Typography>
                    <Typography><strong>Blood Requirements:</strong> {admitData.bloodRequirement || 'N/A'}</Typography>
                    <Typography><strong>Anesthesia:</strong> {admitData.anesthesia || 'N/A'}</Typography>
                    <Typography><strong>Allowed Foods:</strong> {admitData.allowedFoods || 'N/A'}</Typography>
                    <Box mt={2}>
                        <Typography>Created At: {formatDateTime(admitData.createdAt)}</Typography>
                    </Box>
                </Box>
            </Box>
            <hr />
        </>
    );
};  

export default AdmitDataItem;
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/misc/loader";
import Routes from "../../routes/routes";
import { SHOW_SUCCESS_NOTIFICATION } from "../../utils/app-util";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "../../components/misc/autocomplete";
import "../receipt/receipt.css";

import { TextInput } from "sixsprints-react-ui";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Form } from "informed";
import Api from "../../utils/api";
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddCircleOutline } from "@material-ui/icons";
import dayjs from "dayjs";
import useGlobalStyles from "../../utils/global-style";
import AuthService from "../../utils/services/auth-service";
import { IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px", // Adjust the height as needed
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50vh",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  modalStyle_1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "85%",
    background: "white",
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  queue: {
    width: "40px",
    textAlign: "center",
  },
  container: {
    margin: "20px auto",
    width: "96%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
  },
  textStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "10%",
  },

  textMoreStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "4%",
  },
  cellTextStyle: {
    color: theme.palette.common.black,
    fontSize: "0.7rem",
  },
  calledCellStyle: {
    backgroundColor: "#faebd7 ",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "15px",
    marginTop: "20px",
  },
  icon: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    opacity: "0.8",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      opacity: "0.6",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
}));

const Billing = ({ className }) => {
  // const params = useParams()
  const history = useHistory();
  // const { appointmentSlug } = params

  // const slug = appointmentSlug.split("=")[1];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get("slug");
  const isIpdBill = searchParams.get("isIpdBill")

  const [invoice, setInvoice] = useState({});

  const globalClass = useGlobalStyles();
  const wrapperRef = useRef(null);
  const [fetchbillingData, setfetchbillingdata] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open_r, setOpen_r] = useState(false);
  const [open_print, setOpen_print] = useState(false);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [addtoggle, setaddtoggle] = useState(false);
  const [rotate, setRotate] = useState(false);
  const handleClosee = () => {
    setOpen(false);
    setOpen_r(false);
    setOpen_print(false);
  };
  useEffect(() => {
    if (!slug || slug == "") {
      window.open(`/#!` + Routes.APPOINTMENT);
    } else {
      Api.get(`appointment/receipt/print/${slug}`).subscribe((resp) => {
        if (resp.data.data || resp.data.data !== "") {
          setInvoice(resp.data.data);
        } else {
          window.open(`/#!` + Routes.APPOINTMENT);
        }
      });
    }
  }, []);

  const addAppointmentItemstoggle = () => {
    setaddtoggle(!addtoggle);
    setRotate(!rotate);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  //RECEVING
  const handleClickOpen1 = () => {
    setOpen_r(true);
  };

  const handleprint = () => {
    setOpen_print(true);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = AuthService.getUser();
  const [receiptDate, setReceiptDate] = useState(dayjs());

  const [defaultTime, setDefaultTime] = useState(dayjs());
  const [receiptDate_r, setReceiptDate_r] = useState(
    moment(Date.now()).format("DD/MM/YYYY")
  );
  const [defaultTime_r, setDefaultTime_r] = useState(dayjs());

  const handlechangebillingdata = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setformbillingData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlechangebillingdata_r = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setformbillingData_r((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  let date1 = Date.now().valueOf();

  const [surgeryDateTime, setSurgeryDateTime] = useState(
    moment(date1).format("YYYY-MM-DD")
  );
  const [surgeryDateTime_r, setSurgeryDateTime_r] = useState(null);

  const onSelectPrintingTIme = (timeString) => {
    setDefaultTime(timeString);
    if (receiptDate) {
      let surgdate =
        moment(receiptDate).format("YYYY-MM-DD") +
        " " +
        timeString.format("HH:mm:ss");

      setSurgeryDateTime(surgdate);
    }
  };

  const onSelectPrintingTIme_r = (timeString) => {
    setDefaultTime_r(timeString);
    if (receiptDate_r) {
      let surgdate =
        moment(receiptDate_r).format("YYYY-MM-DD") +
        " " +
        timeString.format("HH:mm:ss");

      setSurgeryDateTime_r(surgdate);
    }
  };

  const onReceiptDateSelected = (dateInput) => {
    setReceiptDate(dateInput);
    // setReceiptDate(format(dateInput?.$d, "yyyy-MM-dd"));
    //  setReceiptDate(moment(dateInput).format("DD/MM/YYYY"));
    if (defaultTime) {
      let surgdate =
        moment(dateInput).format("YYYY-MM-DD") +
        " " +
        moment(defaultTime).format("HH:mm:ss");
      // let surgdate = format(dateInput?.$d, "yyyy-MM-dd") + " " + moment(defaultTime).format("HH:mm:ss");

      setSurgeryDateTime(surgdate);
    }
  };

  const onReceiptDateSelected_r = (dateInput) => {
    setReceiptDate_r(dateInput);
    // setReceiptDate_r(format(dateInput?.$d, "dd-MM-yyyy"));
    //  setReceiptDate(moment(dateInput).format("DD/MM/YYYY"));
    if (defaultTime_r) {
      let surgdate =
        moment(dateInput).format("YYYY-MM-DD") +
        " " +
        moment(defaultTime_r).format("HH:mm:ss");

      setSurgeryDateTime_r(surgdate);
    }
  };

  const default_date_time = () => {
    let surgdate = moment(Date.now()).format("DD-MM-YYYY HH:mm:ss");
    setSurgeryDateTime_r(surgdate);
    setSurgeryDateTime(surgdate);
  };
  useEffect(() => {
    default_date_time();
  }, []);
  // print receipt data

  const [receipModalOpen, setReceiptModalOpen] = useState(false);
  const handleReceiptModalOpen = () => setReceiptModalOpen(true);
  const handleReceiptModalClose = () => setReceiptModalOpen(false);
  const [receiptData, setReceiptData] = useState({
    fee: "",
    itemDescription: "",
    discount: "",
    AppointmentNumber: "",
  });
  const [printDateSelected, setPrintDateSelected] = useState();
  const [PrintingTime, setPrintingTime] = useState();
  const onPrintDateSelected = (timeString) => {
    setPrintDateSelected(timeString);
  };
  useEffect(() => {
    setReceiptData({
      fee: totalBill ? totalBill : "0",
      itemDescription: "Consultation charges",
      discount: invoice?.appointment?.discount,
      AppointmentNumber: invoice?.appointment?.slug,
    });
    setReceiptDate(dayjs());
    let date1 = Date.now().valueOf();
    setSurgeryDateTime(
      moment(date1).format("YYYY-MM-DD") +
        " " +
        moment(date1).format("HH:mm:ss")
    );

    setSurgeryDateTime_r(
      moment(date1).format("YYYY-MM-DD") +
        " " +
        moment(date1).format("HH:mm:ss")
    );

    setReceiptDate_r(format(new Date(), "yyyy-MM-dd"));
    setPrintDateSelected(dayjs());
    setPrintingTime(format(new Date(), "hh:mm:ss"));
  }, [invoice, isLoading]);

  const handleReceiptData = (e) => {
    setReceiptData({ ...receiptData, [e.target.name]: e.target.value });
  };

  const saveReceipt = () => {
    if (isChecked) {
      Api.put(`order/receipt/true/${invoice?.appointment?.slug}`).subscribe(
        (resp) => {
          history.push({
            pathname: `${Routes.RECEIPTPRINT}`,
            // pathname: window.open(`#!` + `${Routes.RECEIPTPRINT}`, '_blank'),

            state: {
              receiptData: receiptData,
              printDateSelected: printDateSelected,
              PrintingTime: PrintingTime,
              receiptDate: receiptDate,
              slug: invoice?.appointment?.slug,
              custom: isChecked,
              copy: isChecked2,
              isIpdBill: isIpdBill,
            },
          });
          // window.open(`/#!` + Routes.RECEIPTPRINT + `?slug=${invoice?.appointment?.slug}?`, '_blank')
        }
      );
    } else {
      history.push({
        pathname: `${Routes.RECEIPTPRINT}`,
        search: `?slug=${slug}`,
        state: {
          receiptData: receiptData,
          printDateSelected: printDateSelected,
          PrintingTime: PrintingTime,
          receiptDate: receiptDate,
          slug: slug,
          custom: isChecked,
          copy: isChecked2,
          isIpdBill: isIpdBill,
        },
      });

      // window.open(`/#!` + Routes.RECEIPTPRINT + `?slug=${invoice?.appointment?.slug}?`, '_blank')
    }
  };

  // print receipt data end
  const [formbillingData, setformbillingData] = useState({
    number: 1,
    providerName: user?.name,
    price: 0,
    remarks: "",
    discount: 0,
    finalAmount: 0,
    type: "credit",
    discounttype: "percentage",
    discountvalue: 0,
    billingDateTime:
      moment(receiptDate).format("YYYY-MM-DD") +
      " " +
      moment(defaultTime).format("HH:mm:ss"),
  });

  const [formbillingData_r, setformbillingData_r] = useState({
    providerName: user?.name,
    price: 0,
    remarks: "",
    finalAmount: 0,
    type: "debit",
    billingDateTime:
      moment(receiptDate_r).format("DD-MM-YYYY") +
      " " +
      moment(defaultTime_r).format("HH:mm:ss"),
  });

  const handlefinalAmount = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let final = 0;
    let discount = 0;
    if (name === "discount") {
      const price = formbillingData.price;
      const number = formbillingData.number;
      const dtype = formbillingData.discounttype;
      formbillingData.discountvalue = value;
      final = price * number;
      if (dtype === "percentage") {
        discount = Number(final * value) / 100;
      } else {
        discount = value;
      }
      formbillingData.discount = discount;
      formbillingData.finalAmount = final - discount;
    } else if (name === "price") {
      discount = formbillingData.discountvalue;
      const price = value;
      const number = formbillingData.number;
      const dtype = formbillingData.discounttype;
      final = price * number;
      formbillingData.discountvalue = discount;
      if (dtype === "percentage") {
        discount = Number(final * discount) / 100;
      } else {
        discount = discount;
      }
      formbillingData.discount = discount;
      formbillingData.finalAmount = final - discount;
    } else if (name === "number") {
      discount = formbillingData.discountvalue;
      const price = formbillingData.price;
      const number = value;
      const dtype = formbillingData.discounttype;
      final = price * number;
      if (dtype === "percentage") {
        discount = Number(final * discount) / 100;
      } else {
        discount = discount;
      }
      formbillingData.discount = discount;
      formbillingData.finalAmount = final - discount;
    } else if (name === "discounttype") {
      discount = formbillingData.discountvalue;
      const price = formbillingData.price;
      const number = formbillingData.number;
      const dtype = value;
      final = price * number;
      if (dtype === "percentage") {
        discount = Number(final * discount) / 100;
      } else {
        discount = discount;
      }
      formbillingData.discount = discount;
      formbillingData.finalAmount = final - discount;
    }

    setformbillingData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [currentDataid, setcurrentDataid] = useState(null);
  const [currentActivityName, setCurrentActivityName] = useState();
  const handleClicktext = (data) => {
    // if (data == null) {
    //     setcurrentDataid("")
    //     setCurrentActivityName(data.name)
    // } else {
    setcurrentDataid(data.id);
    setCurrentActivityName(data.name);
    // }

    formbillingData.price = data.price;
    formbillingData.number = 1;

    // let date = Date.now()
    // formbillingData.dateandtime = moment(date).format("DD-MM-YYYY ")
    formbillingData.finalAmount = formbillingData.price;
    formbillingData.discount = 0;
  };
  const handletypetext = (e, data) => {
    if (data == null) {
      setcurrentDataid("");
    } else {
      setcurrentDataid("");
      setCurrentActivityName(data);
    }
  };

  const dataObject = {
    activityName: currentActivityName,
    activityId: currentDataid,
    number: formbillingData.number,
    type: formbillingData.type,
    providerName: formbillingData.providerName,
    billingDateTime: surgeryDateTime,
    price: formbillingData.price,
    remarks: formbillingData.remarks,
    discount: formbillingData.discount,
    discountType: formbillingData.discounttype,
    discountValue: formbillingData.discountvalue,
    totalAmount: formbillingData.finalAmount,
    discountRemark: "",
  };

  const dataObject_r = {
    type: formbillingData_r.type,
    providerName: formbillingData_r.providerName,
    billingDateTime: surgeryDateTime_r,
    price: formbillingData_r.finalAmount,
    remarks: formbillingData_r.remarks,
    totalAmount: formbillingData_r.finalAmount,
  };

  const onactivityFormClicked = () => {
    setIsLoading(true);
    Api.put(`order/billing/add/${slug}`, dataObject).subscribe((resp) => {
      setIsLoading(false);

      SHOW_SUCCESS_NOTIFICATION("Added Successfully");
      setOpen(false);
    });
  };

  //Receving form submit
  const receivingFormClicked = () => {
    setIsLoading(true);
    Api.put(`order/billing/add/${slug}`, dataObject_r).subscribe((resp) => {
      setIsLoading(false);

      SHOW_SUCCESS_NOTIFICATION("Added Successfully");
      setOpen_r(false);
    });
  };

  useEffect(() => {
    Api.post("/admit/activity/search", {
      size: 50,
      page: 0,
      filterModel: {
        status: {
          type: "equals",
          value: "Active",
        },
      },
    }).subscribe((resp) => {
      if (resp) {
        let activityData = resp.data.data?.content;
        setSearchList(activityData);
        setLoading(false);
      }
    });
  }, []);

  const [receivedAmount1, setreceivedAmount] = useState();
  const [totalBill, settotalBill] = useState();

  const [pendingamount, setpendingAmount] = useState();
  const [patientSlug, setpatientSlug] = useState();
  const [dateCreated, setDateCreated] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  useEffect(() => {
    setDataLoading(true);
    Api.get(`order/appointment/${slug}`).subscribe({next: (resp) => {
      setfetchbillingdata(resp.data.data?.billings);
      setreceivedAmount(resp.data.data?.receivedAmount.toFixed(2));

      let TotalBill1 = resp.data.data?.totalBill;
      let roundedTotalBill1 = TotalBill1.toFixed(2);
      settotalBill(roundedTotalBill1);

      setpendingAmount(resp.data.data?.pendingAmount.toFixed(2));
      setpatientSlug(resp.data.data?.patientSlug);
      setDateCreated(resp.data.data?.dateCreated);
      setDataLoading(false);
    },
    error: (error) => {
      setDataLoading(false);
      console.error('response billing error')
    }
  });
  }, [isLoading]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(true);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };

  return (
    <div>
      <Box
        flexGrow="1"
        display="flex"
        justifyContent="space-between"
        mb="1.5rem"
      >
        <div
          style={{
            fontSize: "17px",
            padding: "22px 57px",
            fontFamily: "Comfortaa",
            fontWeight: "bold",
          }}
        >
          BILLING
        </div>
      </Box>
      <div className="second_part d-flex justify-content-between align-items-center ps-2">
        <div className="patient_name">
          <h5>INVOICE TO</h5>
          <p>
            {" "}
            <b>{invoice?.patient?.name} </b> <br />
            <small style={{ fontWeight: "500" }}>
              UHID: &nbsp;{invoice?.patient?.uhid}
            </small>
          </p>
        </div>
        <div>
          <div className="d-flex align-items-center">
            <span className="ps-2 pe-2">
              {" "}
              <b>NO.</b> <br /> #{patientSlug}
            </span>
            <span className="ps-2 pe-2">
              {" "}
              <b>Date:</b> <br />
              {moment(dateCreated).format("DD-MM-YYYY")}
            </span>
          </div>
          <h6
            className="pt-2 pb-2 bg-dark text-white text-center total_price"
            style={{ fontSize: "1.25rem" }}
          >
            TOTAL INR: {totalBill}
          </h6>
        </div>
      </div>
      <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
        <TableContainer component={Paper} style={{ minWidth: "1500px" }}>
          <Table aria-label="simple table" size="small">
            {dataLoading ? (
              <Loader />
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        S.No.
                      </span>
                    </TableCell>
                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        Item Description
                      </span>
                    </TableCell>
                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        Provider
                      </span>
                    </TableCell>
                    {/* <TableCell component='th' ><span style={{ fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "700" }}>Diagnosis</span></TableCell> */}
                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        Date and Time
                      </span>
                    </TableCell>
                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        Cost
                      </span>
                    </TableCell>
                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        Quantitiy
                      </span>
                    </TableCell>

                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        Discount
                      </span>
                    </TableCell>
                    <TableCell component="th">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "700",
                        }}
                      >
                        SubTotal{" "}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchbillingData?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        {row.activityName}
                        {row.remarks !== "" ? (
                          <>
                            <br />
                            {row.remarks}
                          </>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        {row.providerName}
                      </TableCell>
                      {/* <TableCell style={{ padding: "10px", fontWeight: 'bold', fontFamily: "Comfortaa", fontWeight: "500" }} >{row.DateandTime}</TableCell> */}
                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        {moment(row.billingDateTime).format("DD-MM-YYYY") +
                          " " +
                          " " +
                          moment(row.billingDateTime).format("HH:mm:ss")}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        {row.price.toFixed(2)}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        {row.number}
                      </TableCell>

                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        {row?.discountValue > 0 ? (
                          <>
                            {row.discountType == "percentage" ? (
                              <>
                                {row.discount} ({row?.discountValue}%)
                              </>
                            ) : (
                              <>{row?.discountValue.toFixed(2)}</>
                            )}
                          </>
                        ) : (
                          "0"
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          padding: "10px",
                          fontWeight: "bold",
                          fontFamily: "Comfortaa",
                          fontWeight: "500",
                        }}
                      >
                        ({row.type == "credit" ? <>+</> : <>-</>}){" "}
                        {row.totalAmount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </div>
      <div style={{ textAlign: "end", paddingRight: "20px" }}>
        <Typography variant="h4" className={classes.prescriptionTitle}>
          <span style={{ fontWeight: "bolder" }}>Total Bill :</span>&nbsp;
          <span>{totalBill}</span>
        </Typography>
        <Typography variant="h4" className={classes.prescriptionTitle}>
          <span style={{ fontWeight: "bolder" }}>Received Amout :</span>&nbsp;{" "}
          <span>{receivedAmount1}</span>
        </Typography>
        {pendingamount != 0 ? (
          <>
            <Typography variant="h4" className={classes.prescriptionTitle}>
              <span style={{ fontWeight: "bolder" }}>
                {pendingamount > 0 ? "Pending" : "Advance"} Amount :
              </span>
              &nbsp;<span>{pendingamount}</span>
            </Typography>
          </>
        ) : (
          ""
        )}
      </div>

      <Box className={classes.container}>
        <center>
          <Button onClick={handleprint} variant="contained" color="primary">
            Print Preview
          </Button>
        </center>
      </Box>
      {/* dialog for print */}

      {open_print && (
        <Dialog
          open={open_print}
          onClose={handleClosee}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    className={classes.prescriptionTitle}
                  >
                    Receipt
                  </Typography>

                  <CloseIcon
                    onClick={handleClosee}
                    style={{ float: "right", cursor: "pointer" }}
                  />
                </Box>

                {invoice !== null && invoice !== "" ? (
                  <>
                    <Grid style={{ marginTop: "3%" }}>
                      <TextField
                        id="outlined-basic"
                        label="Appointment Number"
                        variant="outlined"
                        field="text"
                        name="AppointmentNumber"
                        value={receiptData.AppointmentNumber}
                        placeholder="Appointment Number"
                        className={classes.style_1}
                        onChange={handleReceiptData}
                      />
                    </Grid>

                    <Grid
                      style={{
                        marginTop: "3%",
                        width: "100%",
                        marginLeft: "2%",
                      }}
                    >
                      <DatePicker
                        label="Receipt Date (DD/MM/YYYY)"
                        value={receiptDate}
                        onChange={(newValue) => {
                          onReceiptDateSelected(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        inputFormat="DD/MM/YYYY"
                        className={globalClass.roundWhiteBg}
                        format="DD/MM/YYYY"
                      />
                    </Grid>

                    <Grid
                      style={{
                        marginTop: "3%",
                        width: "100%",
                        marginLeft: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <DatePicker
                        label="Printing Date (MM/DD/YYYY)"
                        value={printDateSelected}
                        onChange={(newValue) => {
                          onPrintDateSelected(newValue);
                        }}
                        className={globalClass.roundWhiteBg}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" />
                        )}
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                      />
                      <TimePicker
                        label="Printing Time"
                        value={defaultTime || null}
                        onChange={(newValue) => {
                          onSelectPrintingTIme(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={false}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>

                    {isChecked && (
                      <>
                        <Grid style={{ marginTop: "3%" }}>
                          <TextField
                            id="outlined-basic"
                            label="Fees"
                            variant="outlined"
                            field="text"
                            value={totalBill}
                            placeholder="Fees"
                            name="fee"
                            className={classes.style_1}
                            onChange={handleReceiptData}
                          />
                        </Grid>

                        <Grid style={{ marginTop: "3%" }}>
                          <TextField
                            id="outlined-basic"
                            label="Item Description"
                            variant="outlined"
                            field="text"
                            value={receiptData.itemDescription}
                            placeholder="Item Description"
                            name="itemDescription"
                            className={classes.style_1}
                            onChange={handleReceiptData}
                          />
                        </Grid>

                        <Grid style={{ marginTop: "3%" }}>
                          <TextField
                            id="outlined-basic"
                            label="Discount"
                            variant="outlined"
                            field="text"
                            name="discount"
                            value={receiptData.discount}
                            placeholder="Discount"
                            className={classes.style_1}
                            onChange={handleReceiptData}
                          />
                        </Grid>
                      </>
                    )}
                    <FormGroup>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Custom Receipt"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            checked={isChecked2}
                            onChange={handleCheckboxChange2}
                          />
                        }
                        label="Duplicate"
                      />
                    </FormGroup>
                    <Grid>
                      <Box my={6} display="flex" justifyContent="center">
                        <Button
                          style={{ marginRight: 12, padding: 8 }}
                          variant="outlined"
                          onClick={saveReceipt}
                        >
                          Proceed to Print Receipt
                        </Button>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <Loader width="10px" />
                )}
              </Box>
            </Form>
          </DialogContent>
        </Dialog>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          position: "relative",
          top: "130px",
          margin: "10px 30px",
        }}
      >
        {addtoggle && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "fixed",
              bottom: "8px",
              right: "107px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClickOpen1}
              style={{ background: "white" }}
            >
              Receiving
            </Button>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open_r}
              onClose={handleClosee}
            >
              <DialogContent>
                <DialogContentText></DialogContentText>
                <Form>
                  <div style={{ padding: "0px 0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          className={classes.prescriptionTitle}
                        >
                          Add Receiving
                        </Typography>
                        <IconButton onClick={handleClosee}>
                          <CloseIcon style={{cursor: "pointer"}}/>
                        </IconButton>
                      </div>
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <TextInput
                            // style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 5px 20px #00000008", borderRadius: 14, marginTop: 5, marginRight: 10, }}
                            className={classes.style_1}
                            style={{ width: "100%" }}
                            field="provider Name"
                            label="Provider Name"
                            validateOnBlur
                            type="text"
                            validateOnChange
                            name="providerName"
                            value={formbillingData_r.providerName}
                            onChange={handlechangebillingdata_r}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextInput
                            className={classes.style_1}
                            style={{ width: "100%" }}
                            field="price"
                            type="number"
                            label="Amount"
                            validateOnBlur
                            validateOnChange
                            name="finalAmount"
                            value={formbillingData_r.finalAmount}
                            onChange={handlechangebillingdata_r}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextInput
                            className={classes.style_1}
                            style={{ width: "100%" }}
                            field="Remarks"
                            type="text"
                            label="Remarks"
                            validateOnBlur
                            validateOnChange
                            name="remarks"
                            value={formbillingData_r.remarks}
                            onChange={handlechangebillingdata_r}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <DatePicker
                            label=" Date (DD/MM/YYYY)"
                            name="date"
                            // format="dd-MM-yyyy"
                            onChange={(newValue) => {
                              const timestamp_r = newValue
                                ? newValue.valueOf()
                                : null;
                              onReceiptDateSelected_r(timestamp_r);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                              />
                            )}
                            inputFormat="DD/MM/YYYY"
                            sx={{width: "100%"}}
                            className={globalClass.roundWhiteBg}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TimePicker
                            label=" Time"
                            value={defaultTime_r}
                            name="time"
                            onChange={(newValue) => {
                              onSelectPrintingTIme_r(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={false}
                                variant="outlined"
                              />
                            )}
                            sx={{width: "100%"}}
                          />
                        </Grid>
                      </Grid>

                      {/* <Box sx={{ display:"flex" ,justifyContent: 'center', gap: '10px', width: "400px", position: "relative", left: "800px",textAlign:"center" }}> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center !important", // Horizontally center the button
                          alignItems: "center", // Vertically center the button
                          position: "relative",
                          textAlign: "center",
                        }}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {/* <button style={{ borderRadius: "15px", backgroundColor: "black", color: "white", padding: "7px 15px", margin: "20px" }}> Print</button> */}

                        {isLoading ? (
                          <Button variant="contained" color="primary" disabled>
                            <CircularProgress size={20} />
                            Loading
                          </Button>
                        ) : (
                          <button
                            onClick={receivingFormClicked}
                            style={{
                              borderRadius: "15px",
                              backgroundColor: "black",
                              color: "white",
                              padding: "7px 15px",
                              margin: "20px",
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </Box>
                    </Box>
                  </div>
                </Form>
              </DialogContent>
            </Dialog>
            <br />
            {/* <Button style={{ fontSize: "13px" }} variant="outlined" onClick={activityitembtn}  >  Activity / Item </Button> */}
            <Button
              variant="outlined"
              onClick={handleClickOpen}
              style={{ background: "white" }}
            >
              {" "}
              Activity / Item{" "}
            </Button>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open}
              onClose={handleClosee}
            >
              <DialogContent>
                <DialogContentText></DialogContentText>
                <Form>
                  <div style={{ padding: "0px 0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          className={classes.prescriptionTitle}
                        >
                          Add Activity/Item
                        </Typography>
                        <IconButton onClick={handleClosee}>
                          <CloseIcon style={{cursor: "pointer"}}/>
                        </IconButton>
                      </div>
                      <Box></Box>
                      <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12}>
                          <Autocomplete
                            disablePortal
                            id="my-autocomplete"
                            options={searchList}
                            getOptionLabel={(option) => option?.name || ""}
                            onChange={(event, selectedOption) =>
                              handleClicktext(selectedOption)
                            }
                            freeSolo={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Activity Name"
                                variant="outlined"
                                className={classes.style_1}
                                sx={{ width: "100%" }}
                              />
                            )}
                            onInputChange={handletypetext}
                            spanFullWidth={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <TextInput
                            className={classes.style_1}
                            field="provider Name"
                            type="text"
                            label="Provider Name"
                            validateOnBlur
                            validateOnChange
                            name="providerName"
                            value={formbillingData.providerName}
                            onChange={handlechangebillingdata}
                            style={{ width: "100%" }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextInput
                            className={classes.style_1}
                            field="Remarks"
                            type="text"
                            label="Remarks"
                            validateOnBlur
                            validateOnChange
                            name="remarks"
                            value={formbillingData.remarks}
                            onChange={handlechangebillingdata}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <DatePicker
                            label=" Date (DD/MM/YYYY)"
                            value={receiptDate}
                            name="date"
                            onChange={(newValue) => {
                              const timestamp = newValue
                                ? newValue.valueOf()
                                : null;
                              onReceiptDateSelected(timestamp);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" />
                            )}
                            inputFormat="DD/MM/YYYY"
                            className={globalClass.roundWhiteBg}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TimePicker
                            label=" Time"
                            value={defaultTime}
                            name="time"
                            onChange={(newValue) => {
                              onSelectPrintingTIme(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={false}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Discount Type"
                            name="discounttype"
                            value={formbillingData.discounttype}
                            onChange={handlefinalAmount}
                            sx={{ width: "100%" }}
                          >
                            <MenuItem value="percentage">Percentage</MenuItem>
                            <MenuItem value="fix">Fix</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            className={classes.style_1}
                            field="price"
                            type="number"
                            label="Price"
                            validateOnBlur
                            validateOnChange
                            name="price"
                            value={formbillingData.price}
                            onChange={handlefinalAmount}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            className={classes.style_1}
                            field="Discount"
                            type="number"
                            label="Discount"
                            validateOnBlur
                            validateOnChange
                            name="discount"
                            value={formbillingData.discount}
                            onChange={handlefinalAmount}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            className={classes.style_1}
                            field="Number"
                            type="number"
                            label="Number"
                            validateOnBlur
                            validateOnChange
                            name="number"
                            value={formbillingData.number}
                            onChange={handlefinalAmount}
                            sx
                          />
                        </Grid>
                      </Grid>
                      <Typography variant="h5">
                        Final Amount: {formbillingData.finalAmount}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {isLoading ? (
                          <Button variant="contained" color="primary" disabled>
                            <CircularProgress size={20} />
                            Loading
                          </Button>
                        ) : (
                          <button
                            onClick={onactivityFormClicked}
                            style={{
                              borderRadius: "15px",
                              backgroundColor: "black",
                              color: "white",
                              padding: "7px 15px",
                              margin: "20px",
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </Box>
                    </Box>
                  </div>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
        )}

        {
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "2px",
              position: "fixed",
              bottom: " 20px",
              right: "50px",
            }}
          >
            {/* <AddCircleIcon onClick={addAppointmentItemstoggle} style={{ fontSize: "45px", transform: rotate ? "rotate(180deg)" : "none", transition: "transform 0.3s ease", }} /> */}
            <AddCircleOutline
              onClick={addAppointmentItemstoggle}
              style={{
                fontSize: "45px",
                transform: rotate ? "rotate(180deg)" : "none",
                transition: "transform 0.3s ease",
              }}
            />
          </div>
        }
      </div>
    </div>
  );
};
export default Billing;

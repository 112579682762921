import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  '@media print': {
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
  },
}));

const Column = styled(Box)(({ theme }) => ({
  flex: '1 1 300px',
  maxWidth: '100%',
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  marginBottom: '8px',
  '& > *:first-of-type': {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '8px',
    minWidth: '100px',
    flexShrink: 0,
  },
}));

const PatientInfoCard = ({
  patientName,
  roomNumber,
  doctorName,
  patientUhid,
  provisionalDiagnosis,
  plan,
  patientStatus,
}) => {
  return (
    <InfoContainer>
      <Column>
        <InfoItem>
          <Typography variant="subtitle2">Patient Name:</Typography>
          <Typography variant="body1">{patientName}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="subtitle2">Number/UHID:</Typography>
          <Typography variant="body1">{patientUhid}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="subtitle2">Doctor:</Typography>
          <Typography variant="body1">{doctorName}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="subtitle2">Room:</Typography>
          <Typography variant="body1">{roomNumber}</Typography>
        </InfoItem>
      </Column>
      <Column>
        <InfoItem>
          <Typography variant="subtitle2">Diagnosis:</Typography>
          <Typography variant="body1">{provisionalDiagnosis}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="subtitle2">Plan:</Typography>
          <Typography variant="body1">{plan}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="subtitle2">Status:</Typography>
          <Typography variant="body1">{patientStatus}</Typography>
        </InfoItem>
      </Column>
    </InfoContainer>
  );
};

export default PatientInfoCard;

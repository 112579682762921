import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import Axios from "axios";
import clsx from "clsx";
import { format } from "date-fns";
import { Form } from "informed";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/layout/header";
import Loader from "../../components/misc/loader";
import Api from "../../utils/api";
import AppConstants from "../../utils/app-constants";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service";
import PatientForm from "../patient/patient-form";
import { TextInput, SelectInput, CustomDateInput, TextAreaInput, SearchInput } from '../../components/sixsprint-react-ui'

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: theme.spacing(1),
  },
  slots: {
    backgroundColor: "primary",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "25%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
}));

const NewAppointment = () => {
  const location = useLocation();
  const classes = useStyles();
  const [patient, setPatient] = useState(null);
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [doctors, setDoctors] = useState([]);

  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState();

  const [selectedDoc, setSelectedDoc] = useState(null);

  const [slots, setSlots] = useState(null);
  const [printLoading, setIsPrintLoading] = useState(false);
  const [appointmentSlug, setAppointmentSlug] = useState(null);
  const [appointmentType, setAppointmentType] = useState(null);
  const [isEnable, setIsEnable] = useState(false);
  const [appointmentTypeId, setAppointmentTypeId] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [fees, SetFees] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [queue, setQueue] = useState(null);
  const [paymentid, setPaymentId] = useState(null);
  const [endDateTime, setEndDateTime] = useState();
  const [startDateTime, setStartDateTime] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [isFamily, setIsFamily] = useState(false);
  const [isFriends, setIsFriends] = useState(false);
  const [doctorId, setDoctorId] = useState(null);
  const [remark, setRemark] = useState(null);
  const [loader, setLoader] = useState(false);
  const [slotId, setSlotId] = useState(null);
  const [appId, setAppId] = useState(null);

  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [customTimeSlot, setCustomTimeSlot] = useState();

  const [uniqueslotId, setuniqueSlotId] = useState();

  const [otpStatus, setOtpStatus] = useState();

  const [openOtp, setOpenOtp] = React.useState(false);
  const handleOpenOtp = () => setOpenOtp(true);
  const handleCloseOtp = () => setOpenOtp(false);

  const [otp, setOtp] = useState("");

  const handleChangeOtp = async (otp) => {
    setOtp(otp);
  };

  const formApi = useRef();
  const newPatientFormApi = useRef();
  const globalClass = useGlobalStyles();

  useEffect(() => {
    setSelectedDate(format(new Date(), "yyyy-MM-dd"));
    Axios.get(`${process.env.REACT_APP_API2_HOST}getdoctor?active=true`).then(
      (resp) => {
        if (resp) {
          setDoctors(resp.data);
        }
      }
    );
  }, []);

  const fetchSlots = useCallback(
    async (docSlug, date, type) => {
      await Axios.get(
        `${process.env.REACT_APP_API2_HOST}get_slots_of_doctor_by_date?slug=${docSlug}&date=${date}&appointmentId=${type}`
      ).then((resp) => {
        if (resp) {
          setSlots(resp.data.data);
        }
      });
    },
    [selectedDate, appointmentTypeId]
  );

  const onDoctorSelected = async (e) => {
    const doctorSlug = e.target.value;

    setSelectedDoc(doctorSlug.slug);
    setDoctorId(doctorSlug._id);
    setAppointmentTypeId(null);
    await Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_doctor_appointment_types?id=${doctorSlug.slug}`
    ).then((resp) => {
      if (resp) {
        setAppointmentType(resp.data);
      }
    });
  };

  const onAppointmentSelected = (e) => {
    fetchSlots(selectedDoc, selectedDate, e.target.value?._id);
    setAppointmentTypeId(e.target.value?._id);
    setCustomTimeSlot(e.target.value);
    //comment t
    if (uniqueslotId?.length === 0) {
      SHOW_ERROR_NOTIFICATION("Appointment Slot not Available");
    }
  };

  useEffect(() => {}, [location]);

  const [dateError, setDateError] = useState(false);
  const onDateSelected = (val) => {
    const date = new Date(val);
    if (isNaN(date)) {
      if (!dateError) {
        SHOW_ERROR_NOTIFICATION("Invalid date. Please enter a valid date.");
        setDateError(true);
      }
      return;
    } else {
      setDateError(false);
    }
  
    const formattedDate = format(new Date(val), "yyyy-MM-dd");
    setSelectedDate(formattedDate);
  
    if (selectedDoc || appointmentTypeId) {
      fetchSlots(selectedDoc, formattedDate, appointmentTypeId);
    }
  };

  const onSlotSelected = (time, index) => {
    if (selectedTime) {
      setSelectedTime(time.startTimeOnly);
      setCustomTimeSlot();
      return;
    }
    setSelectedTime(time.startTimeOnly);
    setEndDateTime(time.endTimeString);
    setStartDateTime(time.startTimeString);
    setMinutes(time.minutes);
    setSlotId(time.slotId);
    SetFees(time.fee);
    setCustomTimeSlot();

    formApi.current.setValue("fees", time.fee);
  };

  let title = "";
  const onPaymentSelected = (e) => {
    setPaymentId(e.target.value.paymentMode);
    title = e.target.value.paymentModeName + "(" + e.target.value.payTo + ")";
  };

  const onPaymentClick = () => {
    if (paymentMode === null) {
      SHOW_INFO_NOTIFICATION("Please select Doctor");
    }
  };

  const userI = JSON.parse(localStorage.getItem("USER"));

  const handleSubmit = async (val) => {
    setLoader(true);
    if (!selectedDoc || !val.doctorSlug) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_DOC_MSG);
      return;
    }

    // if (!val.fees || fees === ""  ) {
    //   SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_FEES_MSG);
    //   return;
    // }

    if (!selectedTime && !val.slot) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_TIME_MSG);
      return;
    }

    if (!appointmentTypeId) {
      SHOW_INFO_NOTIFICATION("Select Appointment Type");
      return;
    }

    if (!patient || !patient.slug) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.ADD_PATIENT_MSG);
      return;
    }

    val.type = appointmentTypeId || val.type.label;
    val.time = selectedTime || val.slot;
    val.patientSlug = patient.slug;
    val.date = selectedDate;
    val.slug = appointmentSlug;
    val.isFreeChatActive = isSelected;
    val.mobileNumber = mobileNumber;
    val.countryCode = countryCode;

    // checkStatus()
  };

  // const checkStatus = () => {
  //   Axios.get(`${process.env.REACT_APP_API2_HOST}check_doctor_patient?doctorSlug=${selectedDoc}&patientSlug=${patient?.slug}`)
  //     .then((resp) => {
  //       if (resp.data.status === false) {
  //         setOpenOtp(true)
  //         SHOW_INFO_NOTIFICATION(resp.data.message);
  //       }
  //       else {
  //         addAppointment()
  //       }
  //     }
  //     )
  // };

  const [verifyStatus, setVerifyStatus] = useState(false);

  const VerifyOtp = async () => {
    setVerifyStatus(true);
    const formData = new FormData();
    formData.append("otp", otp);
    formData.append("doctorSlug", selectedDoc);
    formData.append("patientSlug", patient.slug);
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}validate_doctor_patient_otp`,
        data: formData,
        headers: { "Content-Type": "application/json" },
      });
      if (response) {
        if (response.data.status === false) {
          setVerifyStatus(false);
          SHOW_INFO_NOTIFICATION(response.data.message);
        } else {
          setOpenOtp(false);
          setOtpStatus(response.data.status);
          setVerifyStatus(false);
          addAppointment();
        }
      }
    } catch {}
  };

  const token = AuthService.getToken();
  const history = useHistory();

  function addMinutes(time, minsToAdd) {
    if (time && minsToAdd) {
      function D(J) {
        return (J < 10 ? "0" : "") + J;
      }
      var piece = time?.split(":");
      var mins = piece[0] * 60 + +piece[1] + +minsToAdd;
      return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
    }
  }

  const addAppointment = async (val) => {
    if (patient === undefined || patient === null) {
      SHOW_INFO_NOTIFICATION("Please Select a Patient");
    } else if (paymentid === undefined || paymentid === null) {
      SHOW_INFO_NOTIFICATION("Please Select Payment Mode");
    } 
    else if (dateError) {
      SHOW_ERROR_NOTIFICATION("Invalid date. Please enter a valid date.");
      return;
    }
    else {
      setLoader(true);
      const formData = new FormData();
      formData.append(
        "startDateTime",
        startDateTime ?? `${selectedDate}` + " " + customTimeSlot
      );
      formData.append(
        "endDateTime",
        endDateTime ??
          `${selectedDate}` +
            " " +
            addMinutes(customTimeSlot, uniqueslotId?.minutes)
      );
      formData.append("doctorSlug", selectedDoc);
      formData.append("patientSlug", patient?.slug);
      formData.append("minutes", minutes ?? uniqueslotId?.minutes);
      formData.append("fee", fees);
      formData.append("creatorName", userI?.name);
      formData.append("creatorRole", userI?.roleName);
      formData.append("creatorNumber", userI?.mobileNumber);
      formData.append("paymentMode", paymentid || "");
      formData.append(
        "appointmentTypeId",
        appointmentTypeId ?? uniqueslotId?.appointmentType
      );
      formData.append("queue", queue || "");
      formData.append("checkIn", isCheckedIn || "");
      formData.append("freeChatPlan", isSelected || "");
      formData.append("isFamily", isFamily);
      formData.append("isFriends", isFriends);
      formData.append("discount", discount);
      formData.append("from", userI?.roleName);
      formData.append("remark", remark);
      formData.append("slotId", slotId ?? uniqueslotId?._id);

      const token = AuthService.getToken();

      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}add_appointment`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-AUTH-TOKEN": token,
          },
        });
        if (response) {
          if (response.data.message.toString() === "Added Successfully!") {
            setLoader(false);
            SHOW_SUCCESS_NOTIFICATION(response.data.message);
            //setAppId(response?.data.data._id)
            update_appointment(response?.data.data._id);
            history.goBack();
          } else {
            setLoader(false);

            SHOW_ERROR_NOTIFICATION(response.data.message);
          }
        }
      } catch (error) {}
    }
  };

  const update_appointment = async (id) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("confirmOrder", true);

    formData.append("status", isCheckedIn ? "CHECKED_IN" : "OPEN");

    formData.append("paymentMode", paymentid);

    const response = await Axios({
      method: "post",
      url: `${process.env.REACT_APP_API2_HOST}update_appointment`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data", "X-AUTH-TOKEN": token },
    });
    if (response) {
      if (
        response.data.message.toString() ===
        "Please provide at least one field to update!"
      ) {
        setLoader(false);
      } else {
        setLoader(false);
        SHOW_SUCCESS_NOTIFICATION(response.data.message);
      }
    }
  };

  const gender = ["MALE", "FEMALE", "OTHERS"];
  const [loading, setLoading] = useState(false);
  const [copyMobileNumber, setCopyMobileNumber] = useState();

  const handleSearch = async (val) => {
    setCopyMobileNumber(val.search);

    setLoading(true);
    if (isAlreadyBooked) {
      formApi.current.reset();
    }
    if (showPatientForm) {
      setShowPatientForm(false);
    }
    await Api.post("appointment/patient/today", {
      mobileNumber: val.search,
    }).subscribe(
      (resp) => {
        if (resp.data.data) {
          setLoading(false);
          let data = resp.data.data;
          setPatient(data.patient);
          formApi.current.setValue("name", data?.patient?.name);
          formApi.current.setValue(
            "dob",
            data?.patient?.dob && moment(data.patient.dob).format("DD/MM/YYYY")
          );
          formApi.current.setValue("mobileNumber", data?.patient?.mobileNumber);
          formApi.current.setValue("uhid", data?.patient?.uhid);
          if (data?.patient?.gender) {
            setLoading(false);
            formApi.current.setValue("gender", data?.patient?.gender);
          }
          if (data.doctor) {
            setLoading(false);
            setIsAlreadyBooked(true);
            setSelectedDoc(data.doctor);
            setSelectedTime(data.time);
            setAppointmentSlug(data.slug);
            if (data.doctor.appointmentType) {
              setAppointmentType(
                data.doctor.appointmentType.filter(
                  (item) => item.active !== false
                )
              );
              formApi.current.setValue("type", data.type);
              setLoading(false);
            }
            formApi.current.setValue("doctorSlug", data.doctor.slug);
            formApi.current.setValue("fees", data.fees);
            formApi.current.setValue("date", data.date);
            formApi.current.setValue("time", data.time);

            setLoading(false);
          }
        } else {
          setShowPatientForm(true);
          newPatientFormApi?.current?.setValue("mobileNumber", val?.search);
          SHOW_INFO_NOTIFICATION(AppConstants.MSG.ADD_PATIENT_MSG);
          setLoading(false);
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setLoading(false);
      }
    );
  };

  const [addLoadingStatus, setAddLoadingStatus] = useState(false);

  const onPatientCreated = (val) => {
    setAddLoadingStatus(true);
    val.roleName = "patient";
    val.password = val.mobileNumber;
    val.mobileNumber = mobileNumber;
    val.countryCode = countryCode;
    Api.post("/patient", val).subscribe(
      (resp) => {
        if (resp.data.status === true) {
          NotificationManager.success("Added Successfully");
          setAddLoadingStatus(false);
          setPatient(resp.data.data);
          setShowPatientForm(false);
          formApi.current.setValue("name", resp.data.data.name);
          formApi.current.setValue(
            "dob",
            resp.data.data.dob &&
              moment(resp.data.data.dob).format("DD/MM/YYYY")
          );
          formApi.current.setValue("mobileNumber", resp.data.data.mobileNumber);
          formApi.current.setValue("uhid", resp.data.data.uhid);
          formApi.current.setValue("gender", resp.data.data.gender);
        } else {
          NotificationManager.error(resp.data.message);
          setAddLoadingStatus(false);
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const handleCheckedInChange = () => {
    setIsCheckedIn(!isCheckedIn);
  };

  const handlePlanSelectedChange = () => {
    setIsSelected(!isSelected);
  };

  const handleisFamilySelectedChange = () => {
    setIsFamily(!isFamily);
  };

  const handlesetIsFriendsSelectedChange = () => {
    setIsFriends(!isFriends);
  };

  const handlePrintReceipt = () => {
    setIsPrintLoading(true);
    DataService.printReceipt(appointmentSlug).subscribe(
      (resp) => {
        setIsPrintLoading(false);
        if (resp.data.receiptPdf) {
          window.open(resp.data.receiptPdf, "_blank");
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const handleAddNew = () => {
    formApi.current.reset();
    setSlots(null);
    setSelectedDoc(null);
    setIsSelected(false);
    setIsCheckedIn(false);
    setAppointmentSlug(null);
    setIsEnable(false);
  };

  const handleChangeStatus = (slug) => {
    const status = "CHECKED_IN";
    Api.put(`appointment/status/${slug}/${status}`).subscribe(
      () => {},
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_doctor_payment_options?id=${doctorId}`
    ).then((resp) => {
      if (resp) {
        for (let i = 0; i < resp?.data?.paymentOptions?.length; i++) {
          let payto = "";
          payto =
            resp.data.paymentOptions.paymentModeName +
            " (" +
            resp.data.paymentOptions.payto +
            " )";
          resp.data.paymentOptions.title = payto;
        }
        setPaymentMode(resp.data.paymentOptions);
        // setRespo(resp.data.data);
      }
    });
  }, [doctorId]);

  const [apoinmentData, setAppoinmentData] = useState({
    fromTime: "",
    toTime: "",
  });

  const [checked, setChecked] = useState(false);

  const handleChangeData = (e) => {
    setAppoinmentData({ ...apoinmentData, [e.target.name]: e.target.value });
  };

  const userId = AuthService?.getUser()?.id;
  const [AllAppoinmentData, setAllAppointmentData] = useState([]);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_appointment_time_doctor?id=${doctorId}`
    ).then((resp) => {
      if (resp) {
        if (resp.data === null) {
          setAllAppointmentData([]);
        } else {
          setAllAppointmentData(resp?.data);
        }
        // setLoading(true)
      }
    });
  }, [doctorId]);

  useEffect(() => {
    if (Array.isArray(AllAppoinmentData)) {
      AllAppoinmentData.map((item) => {
        if (item.appointmentType === appointmentTypeId) {
          setuniqueSlotId(item);
        }
      });
    }
  }, [appointmentTypeId, AllAppoinmentData]);

  const handleTimeChane = (e) => {
    setCustomTimeSlot(e.target.value);
    if (selectedTime) {
      setSelectedTime();
      return;
    }
    setSelectedTime();
    setEndDateTime();
    setStartDateTime();
    setMinutes();
    setSlotId();
    SetFees(uniqueslotId?.fee);
  };

  return (
    <div style={{ minHeight: "100vh", marginLeft: "20" }}>
      <Header showBackBtn title="New Appointment" />
      <Box className={globalClass.boxContainer}>
        {loading ? (
          <Loader width="150px" height="15px" color="primary" />
        ) : (
          <Box
            mt={4}
            width={{ xs: "100%", sm: "40%" }}
            className={globalClass.formContainer}
          >
            <SearchInput
              field="search-patient"
              containerClassName={globalClass.formContainer}
              onSearchSubmit={handleSearch}
              className={globalClass.formElement}
              placeholder="Search by Mobile Number"
              button
              reset
            />
          </Box>
        )}

        {showPatientForm && (
          <PatientForm
            formApi={newPatientFormApi}
            handleSubmit={onPatientCreated}
            setMobileNumber={setMobileNumber}
            setCountryCode={setCountryCode}
            setIsInvalidPhone={setIsInvalidPhone}
            addLoadingStatus={addLoadingStatus}
            copyMobileNumber={copyMobileNumber}
          />
        )}

        <Form
          noValidate
          apiRef={formApi}
          onSubmit={handleSubmit}
          initialValues={{
            date: format(new Date().valueOf(), "yyyy-MM-dd"),
            paymentMode: "Cash",
          }}
        >
          {!showPatientForm && (
            <Box>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <TextInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  required
                  disabled={true}
                  field="uhid"
                  type="text"
                  label="Patient UHID"
                  validateOnBlur
                  validateOnChange
                />

                <TextInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  required
                  disabled={true}
                  field="name"
                  type="text"
                  label="Patient Name"
                  validateOnBlur
                  validateOnChange
                />

                <TextInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  required
                  disabled={true}
                  field="mobileNumber"
                  type="text"
                  label="Patient Number"
                  validateOnBlur
                  validateOnChange
                />

                <TextInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  required
                  disabled={true}
                  field="dob"
                  type="text"
                  label="Patient DOB"
                  validateOnBlur
                  validateOnChange
                />

                <SelectInput
                  required={true}
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  options={gender}
                  disabled={true}
                  field="gender"
                  placeholder="Select Gender"
                  label="Gender"
                  validateOnBlur
                  validateOnChange
                />
              </Grid>

              <div className={globalClass.labelContainer}>
                Consultancy Details
              </div>

              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <CustomDateInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  required
                  inputVariant="outlined"
                  field="date"
                  onChange={(e) => onDateSelected(e)}
                  label="Date (DD/MM/YYYY)"
                  validateOnBlur
                  validateOnChange
                />

                {isAlreadyBooked && (
                  <TextInput
                    className={globalClass.roundWhiteBg}
                    containerClassName={globalClass.inputContainer}
                    required
                    disabled
                    field="time"
                    type="text"
                    label="Appointment Time"
                    validateOnBlur
                    validateOnChange
                  />
                )}

                <SelectInput
                  required={true}
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  options={doctors}
                  onChange={onDoctorSelected}
                  valueKey={[]}
                  labelKey="name"
                  field="doctorSlug"
                  placeholder="Select Doctor"
                  label="Doctor"
                  validateOnBlur
                  validateOnChange
                />

                <SelectInput
                  required={true}
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  options={paymentMode}
                  onChange={onPaymentSelected}
                  onClick={onPaymentClick}
                  valueKey={[]}
                  labelKey="paymentModeName"
                  field="mode"
                  initialValue="Cash"
                  placeholder="Mode of Payment"
                  label="Mode of Payment"
                  validateOnBlur
                  validateOnChange
                />

                <SelectInput
                  required={true}
                  className={globalClass.roundWhiteBg}
                  containerClassName={globalClass.inputContainer}
                  options={appointmentType}
                  onChange={onAppointmentSelected}
                  valueKey=""
                  labelKey="title"
                  field="appointment-type"
                  placeholder="Select Appointment"
                  label="Appointment Type"
                  validateOnBlur
                  validateOnChange
                />

                <TextInput
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth172
                  )}
                  containerClassName={globalClass.inputContainer}
                  onChange={(e) => {
                    SetFees(e.target.value);
                  }}
                  field="fees"
                  label="Fees"
                  type="number"
                  // value={fees}
                  validateOnChange
                  validateOnBlur
                />

                <TextInput
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth172
                  )}
                  containerClassName={globalClass.inputContainer}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  field="discount"
                  type="number"
                  label="Discount"
                  validateOnBlur
                  validateOnChange
                />

                <TextInput
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth
                  )}
                  containerClassName="mr"
                  field="slot"
                  label="Time Slot"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  value={customTimeSlot}
                  onChange={handleTimeChane}
                  validateOnBlur
                  validateOnChange
                />

                <TextInput
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth172
                  )}
                  containerClassName={globalClass.inputContainer}
                  onChange={(e) => {
                    setQueue(e.target.value);
                  }}
                  field="queue"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  label="Queue"
                  validateOnBlur
                  validateOnChange
                />

                <Box
                  mt="0.7rem"
                  px="0.5rem"
                  py="0.4rem"
                  mr="13px"
                  border="1px solid rgba(0, 0, 0, 0.22);"
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.pointer,
                    globalClass.inputWidth173
                  )}
                  containerClassName={globalClass.inputContainer}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckedIn}
                        onChange={handleCheckedInChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Checked In"
                  />
                </Box>

                <Box
                  mt=".8rem"
                  px="0.5rem"
                  py="0.4rem"
                  mr="13px"
                  border="1px solid rgba(0, 0, 0, 0.22);"
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.pointer,
                    globalClass.inputWidth173
                  )}
                  containerClassName={globalClass.inputContainer}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        onChange={handlePlanSelectedChange}
                        name="selectedPlan"
                        color="primary"
                      />
                    }
                    label="Free Chat Plan"
                  />
                </Box>

                <Box
                  mt=".8rem"
                  px="0.6rem"
                  py="0.4rem"
                  ml="0rem"
                  mr="14px"
                  border="1px solid rgba(0, 0, 0, 0.22);"
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.pointer,
                    globalClass.inputWidth173
                  )}
                  containerClassName={globalClass.inputContainer}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFamily}
                        onChange={handleisFamilySelectedChange}
                        name="isFamily"
                        color="primary"
                      />
                    }
                    label="Is Family"
                  />
                </Box>

                <Box
                  mt=".8rem"
                  ml="rem"
                  px="0.7rem"
                  py="0.4rem"
                  border="1px solid rgba(0, 0, 0, 0.22);"
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.pointer,
                    globalClass.inputWidth173
                  )}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFriends}
                        onChange={handlesetIsFriendsSelectedChange}
                        name="isFriends"
                        color="primary"
                      />
                    }
                    label="Is Friends"
                  />
                </Box>
              </Grid>

              <Grid>
                <Box className={globalClass.labelContainer}>Remark</Box>
                <br />

                <TextAreaInput
                  // required
                  style={{ padding: "20px", width: "90%" }}
                  className={clsx(
                    globalClass.roundWhiteBg,
                    globalClass.inputWidth
                  )}
                  containerClassName="mr"
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  field="remakr"
                  type=""
                  InputProps={{ inputProps: { min: 1 } }}
                  label="Remark"
                  validateOnBlur
                  validateOnChange
                />
              </Grid>

              {!checked ? (
                <Grid container>
                  <Grid item sm={12} md={9}>
                    {slots && (
                      <Box m={4} justifyContent="center">
                        <Typography variant="subtitle2">
                          <strong>{"Select Time"}</strong>
                        </Typography>

                        <Box mt={3}>
                          {slots.length === 0 ? (
                            <p>No Slot Available </p>
                          ) : (
                            slots &&
                            slots?.map((item, index) => {
                              // new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(item.minutes);

                              var dis = false;
                              if (!item.available) {
                                dis = true;
                              }
                              return (
                                <Box
                                  className={classes.mb}
                                  display="inline-block"
                                  mr={1}
                                  key={index}
                                >
                                  <Button
                                    onClick={onSlotSelected.bind(
                                      this,
                                      item,
                                      index
                                    )}
                                    color={
                                      selectedTime === item.startTimeOnly
                                        ? "secondary"
                                        : "default"
                                    }
                                    disabled={dis}
                                    variant={
                                      selectedTime === item.startTimeString
                                        ? "contained"
                                        : "outlined"
                                    }
                                    className={classes.slots}
                                  >
                                    {item.startTimeOnly}
                                  </Button>
                                </Box>
                              );
                            })
                          )}
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Box>
          )}

          {!showPatientForm && (
            <Box display="flex" my={5} mx={2}>
              {!isEnable ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={globalClass.largeBtnContainer}
                  onClick={addAppointment}
                >
                  {isAlreadyBooked ? "Confirm Appointment" : "Book Appointment"}
                </Button>
              ) : (
                isCurrentRole(AppConstants.ROLE.RECEPTIONIST) && (
                  <>
                    <Button
                      style={{ marginLeft: "15px" }}
                      variant="contained"
                      color="primary"
                      className={globalClass.largeBtnContainer}
                      onClick={handlePrintReceipt}
                    >
                      {printLoading ? (
                        <CircularProgress
                          disableShrink
                          style={{
                            color: "white",
                          }}
                          size={20}
                        />
                      ) : (
                        "Print Receipt"
                      )}
                    </Button>

                    <Button
                      style={{ marginLeft: "15px" }}
                      variant="contained"
                      color="primary"
                      className={globalClass.largeBtnContainer}
                      onClick={handleAddNew}
                    >
                      {loader ? <Loader /> : "New Appointment"}
                    </Button>
                  </>
                )
              )}
            </Box>
          )}
        </Form>
      </Box>
      {
        <Modal
          open={openOtp}
          onClose={handleCloseOtp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Enter OTP
              </Typography>
              <Close onClick={handleCloseOtp} style={{cursor: "pointer"}}/>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <OtpInput
                value={otp}
                containerStyle={{ textAlign: "center" }}
                inputStyle={{ padding: "10px" }}
                isInputNum={true}
                onChange={handleChangeOtp}
                numInputs={4}
                separator={<span>-</span>}
              />
            </Box>

            <Box mb={3} style={{ marginTop: "2%", float: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
                onClick={VerifyOtp}
              >
                {verifyStatus ? <Loader heigth="20px" /> : "VERIFY OTP"}
              </Button>
            </Box>
          </Box>
        </Modal>
      }
    </div>
  );
};

export default NewAppointment;

import {
  Box,
  Button, Grid,
  makeStyles,
  Paper, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, Select
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
// import { Form, Scope } from "informed";
import { useCallback } from "react";
import { default as React, useEffect, useRef, useState } from "react";
import { TextAreaInput, TextInput } from "../../components/sixsprint-react-ui";
import Header from "../../components/layout/header";
import { Scope } from "informed";
import Axios from "axios";
import AppConstants from "../../utils/app-constants";
import {
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/misc/loader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomToolbar from "../../components/textEditor/CustomTools";
import { ProfileAPI } from "../../apis/ProfileAPI";



const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
    marginTop: "1%"
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // position: "absolute"
  },

  style_3: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    position: "absolute",
    padding: "0.8px"

  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  imageFlex: {
    display: "flex",
    flexDirection: "row",
  },
}));

const Settings = (props) => {
  const [appointmentTypes, setAppointmentTypes] = useState([
    {
      label: "Social Platform",
      active: false,
      disclaimer: "",
    },
    {
      label: "Links",
      active: false,
      disclaimer: "",
    },
  ]);

  const [socialMediaArr, setSocialMediaArr] = useState([
    {
      name: "FACEBOOK",
      link: "",
      id: 1,
    },
    {
      name: "INSTAGRAM",
      link: "",
      id: 2,
    },
    {
      name: "LINKEDIN",
      link: "",
      id: 3,
    },
    {
      name: "WEBSITE",
      link: "",
      id: 4,
    },
    {
      name: "TWITTER",
      link: "",
      id: 5,
    },
  ]);


  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
    ? AuthService.getUser()
    : {};
  const [doctors, setDoctors] = useState([]);
  const formApi = useRef();
  //const [initialValues, setInitialValues] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [profileSettings, setProfileSettings] = useState(null);
  const tenant = AuthService.getTenant();
  const settingsFormData = useRef(profileSettings);

  const handleDebounce = (changeFunction, delay) => {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        settingsFormData.current && changeFunction(settingsFormData.current);
      }, delay);
    };
  };



  ///-----------------------------///
  ///-----------------------------///
  //------------------------------///
  ///-----------------------------///

  const [data, setData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    designation: "",
    registrationNo: "",
    bio: "",
    welcomeEmail: "",
    shortTitle: "",
    speciality: "",
    profilePic: "",
    roomNumber: "",
    bookingNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    latitute: "",
    longitute: "",
    slug: "",
    notes: "",
    mobileNumber: "",
    social_links: "",
  });
  const [profilestate, setProfilestate] = useState(false);
  const [imgData, setImgData] = useState("");
  const [signatureDataImg, setSignatureDataImg] = useState("");

  const [picture, setPicture] = useState("");
  const [signatureImg, setSignatureImg] = useState();
  const [logoStatus, setLogoStatus] = useState(false);
  const [signatureStatus, setSignatureStatus] = useState(false);

  const [specialistData, setSepcilistData] = useState(null);
  const [special, setSpecial] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNumber, setMobileNumber] = useState("");
 
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [respo, setRespo] = useState();
  const [loading, setLoading] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false)

  const [lodingStatus, setLoadingStatus] = useState({
    signLodingStatus: false,
    profilepicLoadingStatus: false,
    formLoadingStatus: false,
    

  })

  //
  const [welcome, setWelcome] = useState('');

  const handleSetWelcome = html =>{
    setWelcome(html)
  }


  const modules = {
    toolbar: {
      container: "#toolbar",
    }
  }
  const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
  ]
  //

  const onChangeData = (event) => {
    if (event.target.name === "slug") {
      let newslug = event.target.value
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      setData({ ...data, [event.target.name]: newslug });
    }
    else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const userId = JSON.parse(localStorage.getItem("USER"));
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getprofile?id=${userId.id}`).then((resp) => {
      if (resp) {

        setRespo(resp.data.data);
        let newmob = resp.data.data?.mobileNumber;
        const number = newmob?.slice(countryCode.length);
        setMobileNumber(number);
        setLoading(true)
      }

    });
  }, [updateStatus]);

  const [SocialTitle, setSocialTitle] = useState("");
  const [url, setUrl] = useState("");
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    setData({
      signature: respo?.signature,
      name: respo?.name,
      email: respo?.email,
      contactNumber: respo?.contactNumber,
      mobileNumber: respo?.mobileNumber,
      designation: respo?.designation,
      registrationNo: respo?.registrationNo,
      bio: respo?.bio,
      shortTitle: respo?.shortTitle,
      roleName: respo?.roleName,
      id: respo?._id,
      profilePic: respo?.profilePic,
      //
      roomNumber: respo?.roomNumber,
      bookingNumber: respo?.booking_number,
      address1: respo?.address,
      address2: respo?.address2,
      city: respo?.city,
      state: respo?.state,
      country: respo?.country,
      pincode: respo?.pincode,
      latitute: respo?.lat,
      longitute: respo?.long,
      slug: respo?.username,
      notes: respo?.notes,
      social_links: respo?.social_links,
      admitPin: respo?.admitPin,
    });
    setInitialValues(respo?.social_links || [])
    setSpecial(respo?.speciality?._id);
    setWelcome(respo?.welcomeEmail)
  }, [loading]);

  const SocialHandleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    const values = [...initialValues];
    if (e.target.name == "name") {
      values[i].link = e.target.value;
    }
    else if (e.target.name == "url") {
      values[i].url = e.target.value;
    }
    setInitialValues(values);
  };


  const handleChangeSelect = (event) => {
    setSpecial(event.target.value);
  };

  const handleInputs = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    
    const name = e.target.name;
    if (name === "profilePic") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
          setLogoStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }


    if (name === "signature") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setSignatureImg(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setSignatureDataImg(reader.result);
          setSignatureStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const roleName = JSON.parse(localStorage.getItem("USER"));

  const handleChangePhoneNumber = useCallback((value, data) => {
    const number = value.slice(data.dialCode.length);
    setMobileNumber(number);
    setCountryCode(`+${data.dialCode}`);
    setIsInvalidPhone(false);
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setSepcilistData(resp.data);
        }
      }
    );
  }, []);

  const handleSaveImage = async (type) => {

    if (type == "profilepic") {
      setLoadingStatus({
        signLodingStatus: false,
        profilepicLoadingStatus: true,
        formLoadingStatus: false,
      })
      const formData = new FormData();
      formData.append("file", picture);
      formData.append("type", type);
      formData.append("website_id", respo?._id);
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/imageuploadbase`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response) {

          if (response.data.toString() == "successfully") {
            setLogoStatus(false);
            setLoadingStatus({
              signLodingStatus: false,
              profilepicLoadingStatus: false,
              formLoadingStatus: false,
            })
            NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          } else {
            NotificationManager.success(
              "Something went wrong Please try again"
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (type == "signature") {
      setLoadingStatus({
        signLodingStatus: true,
        profilepicLoadingStatus: false,
        formLoadingStatus: false,
      })
      const formData = new FormData();
      formData.append("file", signatureImg);
      formData.append("type", "doctorsign");
      formData.append("website_id", respo?._id);
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}/imageuploadbase`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response) {

          if (response.data.toString() == "successfully") {
            setSignatureStatus(false);
            setLoadingStatus({
              logoLodingStatus: false,
              faviconLoadingStatus: false,
              formLoadingStatus: false,
            })
            NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
          } else {
            NotificationManager.success(
              "Something went wrong Please try again"
            );
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  };


  const addRow = async () => {
    let cin = initialValues?.length;
    let ain = socialMediaArr?.length;
    if (cin < ain) {

      await setInitialValues((prev) => [
        ...prev,
        {
          link: socialMediaArr[0].name || "",
          url: url,
        },
      ]);
    }
  };

  const onRemoveRow = (idx) => {
    const values = [...initialValues];
    values.splice(idx, 1);
    for (let i = 0; i < values.length; i++) { }
    setInitialValues(values || []);
    // handleKeyUp();
  };


  let req = "";
   
  const handleSave = async (event) => {
    event.preventDefault();

    if (userId.roleName === null) {
      NotificationManager.success("Something went wrong | Please refresh page");
    }
    { 
      userId.roleName === "DOCTOR_ADMIN" || userId.roleName === "DOCTOR" ?
        req = {
          name: data.name || "",
          speciality: special || "",
          email: data.email || "",
          mobileNumber: `${countryCode + mobileNumber}` || "",
          designation: data.designation || "",
          registrationNo: data.registrationNo || "",
          bio: data.bio || "",
          welcomeEmail: welcome || "",
          shortTitle: data.shortTitle || "",
          role: roleName.roleName || "",
          id: roleName.id || "",
          roomNumber: data.roomNumber || "",
          booking_number: data.bookingNumber || "",
          address: data.address1 || "",
          address2: data.address2 || "",
          city: data.city || "",
          state: data.state || "",
          country: data.country || "",
          pincode: data.pincode || "",
          lat: data.latitute || "",
          long: data.longitute || "",
          slug: data.slug || "",
          notes: data.notes || "",
          social_links: initialValues,
        }

        :

        req = {
          name: data.name || "",
          email: data.email || "",
          mobileNumber: `${countryCode + mobileNumber}` || "",
          bio: data.bio || "",
          role: roleName.roleName || "",
          id: roleName.id || "",
          address: data.address1 || "",
          address2: data.address2 || "",
          city: data.city || "",
          state: data.state || "",
          country: data.country || "",
          pincode: data.pincode || "",
          slug: data.slug || "",
        }
    }

    if (
      data.name === "" ||
      // special ||
      data.contactNumber == "" ||
      data.email === "" ||
      data.slug === ""

    ) {
      SHOW_ERROR_NOTIFICATION(AppConstants.MSG.ALL_FIELDS_REQUIRED);
    } else {
      try {
        setLoadingStatus({
          signLodingStatus: false,
          profilepicLoadingStatus: false,
        formLoadingStatus: true,
        })
        ProfileAPI.updateProfile(req).then((response) => {
          if (response.data.status) {
            NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
            setUpdateStatus(true)
            setLoadingStatus({
              signLodingStatus: false,
              profilepicLoadingStatus: false,
              formLoadingStatus: true,
            })
          } else {
            NotificationManager.error(response.data.message);
          }
        })

      
      } catch (error) {
        setLoadingStatus({
          signLodingStatus: false,
          profilepicLoadingStatus: false,
          formLoadingStatus: true,
        })
      }
    }
  };


  return (

    <div>
      <>
        <Header title="Profile" />
        <form
          apiRef={formApi}
          onSubmit={handleSave}
          initialValues={{
            availSummary: initialValues,
            paymentOptions: [
              {
                payTo: "",
                qrCode: "",
                description: "",
              },
            ],
          }}
        >
          <div style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}>
            <Box display="flex" style={{ marginBottom: "4%" }}>
              <Box className={classes.imageFlex}>
                <Box className={classes.prescriptionSection}>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="h6"
                      className={classes.prescriptionTitle}
                    >
                      Profile Picture
                    </Typography>
                  </Box>
                  <form method="post">
                    <div class="col-12">
                      <input
                        type="file"
                        placeholder="favicon"
                        name="profilePic"
                        class="form-control"
                        id="profilePic"
                        autocomplete="off"
                        onChange={handleInputs}
                        style={{ visibility: "hidden" }}
                      />
                      {!loading ? (<Loader width="150px" height="15px" color="primary"  />):(
                      <div className="previewProfilePic">
                        <img
                          className="playerProfilePic_home_tile"
                          src={imgData || respo?.profilePic}
                          alt="ProfilePIC"
                          width="160"
                          height="160"
                        />
                      </div>
                      )}
                    </div>
                    <br />
                    {logoStatus ? (
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.button}
                        onClick={() => handleSaveImage("profilepic")}
                      >
                        {lodingStatus.profilepicLoadingStatus
                          ? (
                            <Loader width="200px" height="20px" color="primary" />
                          ) : (
                            "SAVE"
                          )}
                      </Button>
                    ) : (
                      <label htmlFor="profilePic">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          className={classes.button}
                        >
                          <EditIcon /> &nbsp; Edit
                        </Button>
                      </label>
                    )}
                    &nbsp;
                  </form>
                </Box>

              </Box>
            </Box>


            <Grid container>
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Personal Details
              </Typography>

              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    field="name"
                    placeholder="Name*"
                    containerClassName="mr"
                    label="Name*"
                    name="name"
                    onChange={onChangeData}
                    value={data.name}
                    className={classes.style_1}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    field="email"
                    placeholder="email*"
                    containerClassName="mr"
                    label="Email*"
                    name="email"
                    onChange={onChangeData}
                    value={data.email}
                    className={classes.style_1}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Grid container style={{height: "100%", paddingRight: "20px"}} alignItems="center">
                    <Grid item style={{width: "100%"}}>
                      <PhoneInput
                        country={"in"}
                        className={classes.style_2}
                        containerClassName="mr"
                        enableSearch
                        label="Staff Number*"
                        field="mobileNumber"
                        value={data?.mobileNumber}
                        countryCodeEditable={false}
                        placeholder="Enter Mobile*"
                        onChange={handleChangePhoneNumber}                       
                        inputStyle={{width:"100%", height:"2.87rem"}}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    field=""
                    placeholder="User Name*"
                    containerClassName="mr"
                    label="User Name*"
                    name="slug"
                    onChange={onChangeData}
                    value={data.slug}
                    className={classes.style_1}
                  />
                </Grid>
                {data.admitPin && data.admitPin !== "" && (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      field="admitPin"
                      placeholder="Admit Pin"
                      containerClassName="mr"
                      label="Admit Pin"
                      name="admitPin"
                      value={data.admitPin}
                      className={classes.style_1}
                      dontUpdate={true}
                    />
                  </Grid>
                )}
              </Grid>


              <>
                <Typography variant="h6" className={classes.prescriptionTitle} mt={6}>
                  Address
                </Typography>

                <Grid container>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      field=""
                      placeholder="Address Line 1"
                      containerClassName="mr"
                      label="Address Line 1"
                      name="address1"
                      onChange={onChangeData}
                      value={data.address1}
                      className={classes.style_1}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      field=""
                      placeholder="Address Line 2"
                      containerClassName="mr"
                      label="Address Line 2"
                      name="address2"
                      onChange={onChangeData}
                      value={data.address2}
                      className={classes.style_1}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      field=""
                      placeholder="City"
                      containerClassName="mr"
                      label="City"
                      name="city"
                      onChange={onChangeData}
                      value={data.city}
                      className={classes.style_1}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      field=""
                      placeholder="State"
                      containerClassName="mr"
                      label="state"
                      name="state"
                      onChange={onChangeData}
                      value={data.state}
                      className={classes.style_1}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      field=""
                      placeholder="Country"
                      containerClassName="mr"
                      label="country"
                      name="country"
                      onChange={onChangeData}
                      value={data.country}
                      className={classes.style_1}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextInput
                      field=""
                      type="number"
                      placeholder="Pincode"
                      containerClassName="mr"
                      label="Pincode"
                      name="pincode"
                      onChange={onChangeData}
                      value={data.pincode}
                      className={classes.style_1}
                    />
                  </Grid>

                  {
                    userId.roleName === "DOCTOR_ADMIN" || userId.roleName === "DOCTOR" ?
                      <>


                        <Grid item xs={12} sm={6} md={4}>
                          <TextInput
                            field=""
                            type="number"
                            placeholder="Latitute"
                            label="Latitute"
                            name="latitute"
                            containerClassName="mr"
                            onChange={onChangeData}
                            value={data.latitute}
                            className={classes.style_1}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextInput
                            field=""
                            type="number"
                            placeholder="Longitute"
                            containerClassName="mr"
                            label="Longitute"
                            name="longitute"
                            onChange={onChangeData}
                            value={data.longitute}
                            className={classes.style_1}
                          />
                        </Grid>
                      </>
                      :
                      null
                  }

                </Grid>

                {
                  userId.roleName === "DOCTOR_ADMIN" || userId.roleName === "DOCTOR" ?
                    <>


                      <Typography variant="h6" className={classes.prescriptionTitle}>
                        Professional Details
                      </Typography>

                      <Grid container>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextInput
                            field="designation"
                            placeholder="designation"
                            containerClassName="mr"
                            label="Designation"
                            name="designation"
                            onChange={onChangeData}
                            value={data.designation}
                            className={classes.style_1}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextInput
                            field="registrationNo"
                            placeholder="registration no"
                            containerClassName="mr"
                            label="RegistrationNo"
                            name="registrationNo"
                            onChange={onChangeData}
                            value={data.registrationNo}
                            className={classes.style_1}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextInput
                            field=""
                            type="number"
                            placeholder="Room Number"
                            containerClassName="mr"
                            label="Room Number"
                            name="roomNumber"
                            onChange={onChangeData}
                            value={data.roomNumber}
                            className={classes.style_1}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextInput
                            field=""
                            type="number"
                            placeholder="Booking Contact Number"
                            containerClassName="mr"
                            label="Booking Contact Number"
                            name="bookingNumber"
                            onChange={onChangeData}
                            value={data.bookingNumber}
                            className={classes.style_1}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} style={{ position: "relative" }}>

                          <Select
                            variant="outlined"
                            labelId="demo-select-label"
                            containerClassName="mr"
                            id="demo-select"
                            label="Specilist"
                            value={special}
                            onChange={handleChangeSelect}
                            className={classes.style_1}
                            style={{ position: "absolute", top: "17px", width: "95%" }}
                          >
                            {specialistData?.map((value) => (
                              <MenuItem value={value._id}>{value.name}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </>
                    : null
                }

              </>

            </Grid>

            <Box className={classes.prescriptionSection}>
                    <Box
                      mt="2rem"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" className={classes.prescriptionTitle}>
                        Bio Title
                      </Typography>
                    </Box>

                    <Paper
                      className={clsx(classes.container, globalClass.roundWhiteBg)}
                    >
                      <TextAreaInput
                        field="shortTitle"
                        name="shortTitle"
                        placeholder="Type here"
                        onChange={onChangeData}
                        value={data.shortTitle}
                      />
                    </Paper>
                  </Box>


            <Box className={classes.prescriptionSection}>
              <Box
                mt="5rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.prescriptionTitle}>
                  Bio
                </Typography>
              </Box>

              <Paper
                className={clsx(classes.container, globalClass.roundWhiteBg)}
              >
                <TextAreaInput
                  field="bio"
                  name="bio"
                  placeholder="Type here"
                  onChange={onChangeData}
                  value={data.bio}
                />
              </Paper>
            </Box>

            {
              userId.roleName === "DOCTOR_ADMIN" || userId.roleName === "DOCTOR" ?
                <>
                  <Box className={classes.prescriptionSection}>
                    <Box
                      mt="2rem"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" className={classes.prescriptionTitle}>
                        Social Links
                      </Typography>
                    </Box>
                    <Paper
                      className={clsx(classes.container, globalClass.roundWhiteBg)}
                    >
                      <Box className={classes.prescriptionSection}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<AddIcon />}
                          onClick={addRow}
                        >
                          ADD
                        </Button>
                      </Box>

                      <TableContainer>
                        <Table
                          aria-label="simple table"
                          id="summaryRow"
                          className={classes.table}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <strong>Social Plateform</strong>
                              </TableCell>
                              <TableCell>
                                <strong>link</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {initialValues?.map((item, i) => {

                              return (
                                <Scope scope={`availSummary[${i}]`} key={i}>
                                  <TableRow key={i}>
                                    <TableCell>
                                      <Box display="flex" flexDirection="column">

                                        <Select
                                          labelId="demo-simple-select-label"
                                          id={`demo-simple-select-${i}`}
                                          name="name"
                                          variant="outlined"
                                          value={item.link}
                                          onChange={(e) => SocialHandleChange(e, i)}
                                        >
                                          {
                                            socialMediaArr.map((value) => {
                                              let selected = "false"
                                              if (item?.link == value.name) {
                                                selected = "true";
                                              }
                                              return (
                                                <MenuItem value={value.name} selected={selected}>{value.name} </MenuItem>
                                              )
                                            })
                                          }
                                        </Select>

                                      </Box>
                                    </TableCell>

                                    <TableCell>
                                      <Box display="flex" flexDirection="column">
                                        <TextInput
                                          field="Name"
                                          placeholder="URL"
                                          containerClassName="Name"
                                          label="Name"
                                          type="text"
                                          value={item.url}
                                          validateOnBlur
                                          validateOnChange
                                          name="url"
                                          onChange={(e) => SocialHandleChange(e, i)}
                                          className={classes.style_1}
                                        />
                                      </Box>
                                    </TableCell>

                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        startIcon={<DeleteIcon />}
                                        onClick={() => onRemoveRow(i)}
                                      >
                                        Remove
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                </Scope>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>




                  <Box className={classes.prescriptionSection}>
                    <Box
                      mt="2rem"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" className={classes.prescriptionTitle}>
                        Announcement and Notice
                      </Typography>
                    </Box>

                    <Paper
                      className={clsx(classes.container, globalClass.roundWhiteBg)}
                    >
                      <TextAreaInput
                        field="bio"
                        name="notes"
                        placeholder="Type here"
                        onChange={onChangeData}
                        value={data.notes}
                      />
                    </Paper>
                  </Box>

                  <Box className={classes.prescriptionSection}>
                    <Box
                      mt="2rem"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6" className={classes.prescriptionTitle}>
                        Welcome Email
                      </Typography>
                    </Box>

                    <Paper
                      className={clsx(classes.container, globalClass.roundWhiteBg)}
                    >
                      {/* <TextAreaInput
                        welcomeEmail="welcomeEmail"
                        name="welcomeEmail"
                        field="welcomeEmail"
                        placeholder="Type here"
                        onChange={onChangeData}
                        value={data.welcomeEmail}
                      /> */}

                      <CustomToolbar />
                      <ReactQuill
                        theme="snow"
                        value={welcome}
                        modules={modules}
                        formats={formats}
                        onChange={handleSetWelcome}
                      />
                    </Paper>
                  </Box>

                


                  <Box className={classes.prescriptionSection}>
                    <Box
                      mt="2rem"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        Signature
                      </Typography>
                    </Box>
                    <form method="post">
                      <div class="col-12">
                        <input
                          type="file"
                          placeholder="signature"
                          name="signature"
                          class="form-control"
                          id="signature"
                          autocomplete="off"
                          onChange={handleInputs}
                          style={{ visibility: "hidden" }}
                        />
                        <div className="previewProfilePic">
                        {!loading ? (<Loader width="150px" height="15px" color="primary" />):(
                          <img
                            className="playerProfilePic_home_tile"
                            src={signatureDataImg || data.signature}
                            alt="signature"
                            width="160"
                          />
                        )}
                        </div>
                      </div>
                      <br />
                      {signatureStatus ? (
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          className={classes.button}
                          onClick={() => handleSaveImage("signature")}
                        >
                          {lodingStatus.signLodingStatus ? <Loader width="20px" height="20px" color="primary" /> : "SAVE"}
                        </Button>

                      ) : (
                        <label htmlFor="signature">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className={classes.button}
                          >
                            <EditIcon /> &nbsp; Edit
                          </Button>
                        </label>
                      )}
                      &nbsp;
                    </form>
                  </Box>

                </>

                : null
            }

            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
              >
                SAVE
              </Button>
            </Box>
          </div>
        </form>
      </>
    </div>
  );
};

export default Settings;

import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { SearchInput } from 'sixsprints-react-ui';
import ListIcon from '@material-ui/icons/List';
import SelectInput from '../form/select-input';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBackOutlined';
import {
  Hidden,
  IconButton,
  Button,
  ButtonGroup,
  Box,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { SidebarContext } from '../../context/sidebar';
import { useHistory, useLocation } from 'react-router-dom';
import useGlobalStyles from '../../utils/global-style';
import clsx from 'clsx';
import AuthService from '../../utils/services/auth-service';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import AsyncDrpdwn from '../form/async-drpdwn';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(10),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: 'bold',
    opacity: '0.9',
  },
  adjustRightmargin: {
    marginRight: theme.spacing(10),
  },
  mb8px: {
    marginBottom: '8px',
  },
}));

const Header = ({
  title,
  onGroupBtnClick,
  isListView,
  toggleSidebar,
  onAddNew,
  onSearch,
  onReset,
  toggleChatSidebar,
  showBackBtn,
  checked,
  onToggleEditMode,
  onOptionSelected,
  doctors,
  onDoctorSelected,
  patients,
  onPatientSelected,
  appnmtDate,
  onAppnmtDateSelected,
  sortingOptions,
  sortOptionSelected,
  onSortOptionSelected,
  handleClickGroupBtn,
  allDoctors,
  showMenuIconInMobileView = true,
}) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const history = useHistory();
  const sidebarContext = SidebarContext.useContainer();
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  const isPrimaryProfile = useSelector(state => state?.switchProfile?.isPrimaryProfile);

  const handleBack = () => {
    history.goBack();
  };

  const handleActiveTab = (tab) => {
    handleClickGroupBtn(tab);
    setActiveTab(tab);
  };
  
  useEffect(() => {
      setActiveTab(AuthService.getActiveTab());
      // if (user.roleName === "DOCTOR_ADMIN" || user.roleName === "DOCTOR") {
      //  return(
      //    onDoctorSelected(user.slug),
      //    setDoctorValue(user.slug)
      //    )
      // }
  }, []);

  const user = JSON.parse(localStorage.getItem("USER"))

  const [doctorValue, setDoctorValue] = useState();



  return (
    <div className={classes.grow}>
      <AppBar
        className={classes.appbar}
        color="default"
        position="static"
        elevation={0}
      >
        <Toolbar>
          {showBackBtn && (
            <IconButton color="inherit" onClick={handleBack}>
              <BackIcon />
            </IconButton>
          )}
          {showMenuIconInMobileView && location.pathname !== "/app/appointment_dashboard" && (
            <Hidden lgUp>
              <IconButton
                color="inherit"
                onClick={sidebarContext.handleSidebarOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          {toggleChatSidebar && (
            <Button
              onClick={toggleChatSidebar}
              variant="contained"
              color="primary"
              className={classes.adjustRightmargin}
            >
              Show Info
            </Button>
          )}
          {toggleSidebar && (
            <Button
              onClick={toggleSidebar}
              variant="contained"
              color="primary"
              disableElevation
            >
              Show Info
            </Button>
          )}

          {onSearch && (
            <Box mx="1rem">
              <SearchInput
                reset
                containerClassName={globalClass.formContainer}
                onSearchSubmit={onSearch}
                className={clsx(classes.searchInput, globalClass.formElement)}
                placeholder="Search here .."
                button
              />
            </Box>
          )}
          {onReset && (
            <Box mx="1rem">
              <Button
                variant="contained"
                color="primary"
                onClick={onReset}
                disableElevation
              >
                Reset
              </Button>
            </Box>
          )}

          {onSortOptionSelected && (
            <Box mx={2}>
              <SelectInput
                required={true}
                variant="standard"
                containerClassName="mr"
                labelKey="label"
                valueKey="value"
                options={sortingOptions}
                initialValue={"Date"}
                label={"Sort By"}
                onChange={onSortOptionSelected}
                value={sortOptionSelected}
                validateOnBlur
                validateOnChange
              />
            </Box>
          )}

          {doctors && user.roleName !== "DOCTOR" ? (
            <Box mr={10}>
              <AsyncDrpdwn
                baseUrl="doctor"
                searchColumns={["name"]}
                useMaterialStyle
                isFloatLabel
                isClearable
                backspaceRemovesValue={false}
                openMenuOnClick={false}
                dropdownIndicator={false}
                placeholder="Doctors"
                getOptionLabel={(option) => option.name}
                onChange={(option) => {
                  const val = option ? option.slug : null;
                  onDoctorSelected(val);
                  setDoctorValue(val);
                }}
              />
            </Box>
          ) : null}

          {patients && (
            <Box mr={10}>
              <AsyncDrpdwn
                doctorValue={doctorValue}
                baseUrl="patient"
                searchColumns={["name", "mobileNumber"]}
                useMaterialStyle
                isFloatLabel
                isClearable
                backspaceRemovesValue={false}
                openMenuOnClick={false}
                dropdownIndicator={false}
                placeholder="Patients"
                getOptionLabel={(option) =>
                  `${option.name} (${option.mobileNumber})`
                }
                onChange={async (option) => {
                  const val = option ? option.slug : null;
                  console.log("test_patientslug", val);
                  await onPatientSelected(val);
                }}
              />
            </Box>
          )}

          {onAppnmtDateSelected && (
            <Box mx={2}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                  <KeyboardDatePicker
                    className={`MuiFormControl-marginNormal m-0`}
                    inputVariant="standard"
                    format="DD/MM/YYYY"
                    label="Appointment Date"
                    value={appnmtDate}
                    onChange={(momentDate) =>
                      momentDate && onAppnmtDateSelected(momentDate)
                    }
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Box>
          )}

          {/* {onOptionSelected && (
              <Box mx={2}>
                <SelectInput
                  required={true}
                  variant="standard"
                  containerClassName="mr"
                  options={["Yesterday", "Today", "Tomorrow", "All"]}
                  onChange={onOptionSelected}
                  value={selectedValue}
                  validateOnBlur
                  validateOnChange
                />
              </Box>
            )} */}

          {onAddNew && isPrimaryProfile && (
            <Box mx={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={onAddNew}
                disableElevation
              >
                Add
              </Button>
            </Box>
          )}
          {onToggleEditMode && (
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={onToggleEditMode}
                  color="primary"
                />
              }
              label="Edit"
            />
          )}
          {onGroupBtnClick && (
            <>
              <ButtonGroup
                color="primary"
                aria-label="contained primary button group"
              >
                <Button
                  variant={activeTab === 0 && "contained"}
                  onClick={() => {
                    handleActiveTab(0);
                  }}
                >
                  <ListIcon />
                </Button>

                {(user.roleName === "DOCTOR_ADMIN" ||
                  user.roleName === "DOCTOR") &&
                isPrimaryProfile ? (
                  <Button
                    variant={activeTab === 1 && "contained"}
                    onClick={() => {
                      handleActiveTab(1);
                    }}
                  >
                    <CalendarTodayIcon />
                  </Button>
                ) : null}

                <Button
                  variant={activeTab === 2 && "contained"}
                  onClick={() => {
                    handleActiveTab(2);
                  }}
                >
                  <DashboardIcon />
                </Button>
              </ButtonGroup>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;

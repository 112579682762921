import React, { useEffect, useState, useCallback, useRef } from "react";
import GenericGrid from "../../components/grid/generic-grid";
import PatientColumn from "./patient-column";
import useGlobalStyles from "../../utils/global-style";
import PrespHistoryDialog from "./prescription-history-dialog";
import { useHistory } from "react-router-dom";
import Api from "../../utils/api";
import Routes from "../../routes/routes";
import Axios from "axios";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Box, makeStyles,
  CircularProgress,
  Typography
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    width: "80%",
    height: "auto",
    //overflow: 'scroll',
  },
  searchBar: {
    marginBottom: 12,
    border: "1px solid gray",
    outline: "none",
  },
  container: {
    width: "78%%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
    height: "80vh",
    position: "absolute",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "20px",
    marginTop: "15px",
  },
  striky: {
    position: "-webkit-sticky",
    position: "sticky",
    zIndex: 5,
  },
}));

const Patient = () => {
  const classes = useStyles();
  const history = useHistory("");
  const globalClasses = useGlobalStyles();
  const [toggle, setToggle] = useState(false);
  const [patientObj, setPatientObj] = useState({});
  const [allData, setAllData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPage(0);
  };

  // for switched profile
  const isPrimaryProfile = useSelector(state => state?.switchProfile?.isPrimaryProfile);
  const primaryProfileInfo = useSelector(state => state?.switchProfile?.primaryProfileInfo);
  const switchedProfileInfo = useSelector(state => state?.switchProfile?.switchedProfileInfo);

  const onToggleMode = () => {
    setToggle(!toggle);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const lastLogElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreLogs();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const onEyeBtnClicked = (val) => {
    setPatientObj(val);
    onToggleMode();
  };

  const onChatBtnClick = (val) => {
    Api.get(`chat-session/patient/session?patientSlug=${val.slug}`).subscribe(
      (resp) => {
        if (resp) {
          history.push({
            pathname: Routes.CHAT,
            state: resp,
          });
        } else {
        }
      }
    );
  };

  const fetchLogs = (obj, pageNum, pageSize) => {
    setLoading(true);
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_all_logs?slug=${obj.slug}&page=${pageNum}&size=${pageSize}`
    ).then((resp) => {
      if (resp && resp.data && Array.isArray(resp.data.data)) {
        const sortedData = resp.data.data.sort((a, b) => b.dateTime - a.dateTime);
        setAllData(sortedData);
        setTotalCount(resp.data.totalCount || 0);
        setHasMore(resp.data.data.length === pageSize);
        setPage(pageNum);
      } else {
        setAllData([]);
        setTotalCount(0);
        setHasMore(false);
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching logs:", error);
      setAllData([]);
      setTotalCount(0);
      setHasMore(false);
      setLoading(false);
    });
  };

  const fetchMoreLogs = () => {
    if (loading || !hasMore) return;
    const nextPage = page + 1;
    setLoading(true);
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_all_logs?slug=${patientObj.slug}&page=${nextPage}&size=${rowsPerPage}`
    ).then((resp) => {
      if (resp && resp.data && Array.isArray(resp.data.data)) {
        const sortedNewData = resp.data.data.sort((a, b) => b.dateTime - a.dateTime);
        setAllData((prevData) => {
          const combinedData = [...prevData, ...sortedNewData];
          return combinedData.sort((a, b) => b.dateTime - a.dateTime);
        });
        setTotalCount(resp.data.totalCount || 0);
        setHasMore(resp.data.data.length === rowsPerPage);
        setPage(nextPage);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching more logs:", error);
      setHasMore(false);
      setLoading(false);
    });
  };

  const onLogButtonClicked = (val) => {
    setPatientObj(val);
    fetchLogs(val, page, rowsPerPage);
    handleOpen();
  };

  const onMailButtonClicked = (val) => {
    // const res=Axios.post(`${process.env.REACT_APP_API2_HOST}send_custom_msg?slug=${val?.slug}`)
    // setPatientObj(val);
  };

  const user = JSON.parse(localStorage.getItem("USER"));
  const [defaultFilter, setDefaultFilter] = useState({});

  useEffect(() => {
    if (user.roleName === "DOCTOR_ADMIN" || user.roleName === "DOCTOR") {
      const currentDoctorSlug = isPrimaryProfile
        ? primaryProfileInfo?.slug
        : switchedProfileInfo?.doctorSlug;
      setDefaultFilter({
        custom: {
          filterType: "exact",
          filter: `doctorPatients::slug::${currentDoctorSlug}`,
        },
      })
      
    } else {
      setDefaultFilter({})
    }
  }, [isPrimaryProfile]);

  const formatTime = (timeString) => {
    const dat = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timeString);
    const vad = format(new Date(dat), "dd-MM-yyyy");
    if (!timeString) {
      return "N/A";
    }

    return vad;
  };

  return (
    <>
      <div className={globalClasses.addSpacing}>
        <GenericGrid
          baseUrl="patient"
          hideExport={false}
          onEyeBtnClicked={onEyeBtnClicked}
          onChatBtnClick={onChatBtnClick}
          onLogButtonClicked={onLogButtonClicked}
          onMailButtonClicked={onMailButtonClicked}
          hideOnUpload={false}
          hideOnCopy={true}
          LogButton={true}
          csvName="patient"
          hideDelete={false}
          columnDefGenerator={PatientColumn}
          title="Patient"
          defaultFilter={defaultFilter}
          key={isPrimaryProfile
            ? primaryProfileInfo?.slug
            : switchedProfileInfo?.doctorSlug}
        />
        {
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ Height: "71vh" }}
          >
            <Box className={classes.modalStyle}>
              <CloseIcon onClick={handleClose} style={{ float: "right", cursor: "pointer" }} />
              <Typography variant="h5" className={classes.prescriptionTitle}>
                Patients Logs
              </Typography>
              <Box
                style={{
                  border: "1px solid gray ",
                  position: "relative",
                  overflow: "scroll",
                  height: "66vh",
                }}
              >
                {allData !== null ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                      <TableHead className={classes.striky}>
                        <TableRow className={classes.striky}>
                          <TableCell
                            component="th"
                            className={classes.textStyle}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            component="th"
                            className={classes.textStyle}
                          >
                            Message
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allData?.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Stack sx={{ width: "100%" }} spacing={2}>
                                <Typography align="center">No data available</Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ) : (
                          allData?.map((item, index) => (
                            <TableRow
                              key={item.id}
                              ref={index === allData.length - 1 ? lastLogElementRef : null}
                            >
                              <TableCell
                                className={classes.cellTextStyle}
                                style={{ padding: "2%" }}
                              >
                                <Typography>
                                  {formatTime(item.dateTime)}
                                </Typography>
                              </TableCell>

                              <TableCell
                                className={classes.cellTextStyle}
                                style={{ padding: "2%" }}
                              >
                                <Typography> {item.message}</Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                {loading && (
                  <div style={{ textAlign: "center", padding: "10px" }}>
                    <CircularProgress size={24} />
                  </div>
                )}
              </Box>
            </Box>
          </Modal>
        }

        {/* Report modal */}
      </div>

      <PrespHistoryDialog
        handleClose={onToggleMode}
        open={toggle}
        patientObj={patientObj}
      />
    </>
  );
};

export default Patient;
import React, { useState, useRef, useEffect, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../../components/table/table-pagination";
import Routes from "../../routes/routes";
import clsx from "clsx";
import { getFormattedDate } from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ThemeProvider, createTheme } from "@mui/material";
import {
  SHOW_SUCCESS_NOTIFICATION,
  DEFAULT_ERROR_CALLBACK,
} from "../../utils/app-util";
import DataService from "../../utils/services/data-service";
import Api from "../../utils/api";
import AppConstants from "../../utils/app-constants";
import OralCare from "../../assets/OralCare.png";
import Poop from "../../assets/poop.png";
import Bedding from "../../assets/bedding.png";
import Flatus from "../../assets/passedflatus.png";
import { Warning } from "@material-ui/icons";
import StatusChecklistModal from "./StatusChecklistModal";
import DescriptionIcon from '@material-ui/icons/Description';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PeopleIcon from '@material-ui/icons/People';
import RoomSelectionModal from "./RoomSelectionModal";

import {
  IconButton,
  Box, TablePagination,
  makeStyles,
  Tooltip,
  Modal,
  Typography,
  Grid,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  FormControl
} from "@material-ui/core";
import Loader from "../../components/misc/loader";
import { useGetAllActiveRooms } from "../../apis/AdmitAPI";
import { useQueryClient } from "@tanstack/react-query";
import WarningDialog from "./ipd/WarningDialog";
import BathtubSharp from '@material-ui/icons/BathtubSharp';

const myTheme = createTheme({
  // style radio button as button element
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#293264",
          margin: "5px 15px 0 0",
          padding: "2px 8px",
          width: "max-content",
          borderStyle: "none",
          border: "1px solid !important",
          borderRadius: "13px!important",
          "&.Mui-selected": {
            backgroundColor: "#D6DBF5",
            borderStyle: "none!important",
          },
          "&:hover": {
            backgroundColor: "#D6DBF5",
          },
        },
        grouped: {
          backgroundColor: "#D6DBF5",
          borderStyle: "none!important",
          "&:hover": {
            backgroundColor: "#D6DBF5",
          },
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  queue: {
    width: "40px",
    textAlign: "center",
  },
  presBox: {
    height: "20vh",
    overflowY: "scroll",
  },
  container: {
    margin: "20px auto",
    width: "96%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(0, 6, 6, 6),
  },
  textStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "10%",
  },
  textMoreStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "4%",
  },
  cellTextStyle: {
    color: theme.palette.common.black,
    fontSize: "0.7rem",
  },
  calledCellStyle: {
    backgroundColor: "#faebd7",
  },
  header_styling: {
    width: "1%",
  },
  body_styling: {
    width: "1%",
  },

  icon: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    opacity: "0.8",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      opacity: "0.6",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },

}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AdmittedList = ({ className, doctorSlug, currentdate, patientSlug }) => {
  const queryClient = useQueryClient();
  const isInitialRender = useRef(true);
 
  const [isLoading, setIsLoading] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  const [open, setOpen] = useState(false);
  const [openRep, setReport] = useState(false);
  const globalClass = useGlobalStyles();
  const [selectedAdmit, setSelectedAdmit] = useState({});

  const [admitData, setAdmitData] = useState([]);

  const [oldStatus, setOldStatus] = useState({});
  const [newStatus, setNewStatus] = useState({});

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    console.log("admittest_first", doctorSlug);
  }, [doctorSlug]);

  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 10000);
  }, [refresh]);

  const {
    data: allActiveRooms,
    isLoading: roomsLoading,
    error: roomsError,
  } = useGetAllActiveRooms();

  const [addroomno, setRoomno] = useState({
    roomNumber: "",
  });
  // const [addstatus, setaddstatus] = useState({
  //     roomNumber: ""

  // })

  const handlechangeroomno = (event) => {
    const { name, value } = event.target;

    setRoomno((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveandUpdateRoom = () => {
    selectedAdmit.roomNumber = addroomno.roomNumber;

    Api.put(`admit/edit/${selectedAdmit.id}`, selectedAdmit).subscribe(
      (resp) => {
        setOpen(false);
        SHOW_SUCCESS_NOTIFICATION("Updated sucessfully");
        // Invalidate and refetch the useGetAllActiveRooms query
        queryClient.invalidateQueries('allActiveRooms');
      
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const classes = useStyles();

  const handleClosed = () => {
    setOpen(false);
  };

  // const viewadmitted = (rowdata) => {
  //     // console.log("test_id",rowdata);
  //     // let id = rowdata.id;
  //     history.push(Routes.ADMITEDPROFILE);
  // };
  const viewadmitted = (rowdata) => {
    let id2 = rowdata.id;
    window.location.href = `#!` + `${Routes.ADMITEDPROFILE}/${id2}`;
  };
  const viewBilling = (rowdata) => {
    let Appointslug = rowdata.appointmentSlug;
    window.location.href = `#!` + `${Routes.BILLING}?slug=${Appointslug}&isIpdBill=true`;
    // history.push( `${Routes.BILLING}?slug=${Appointslug}`);
  };
  const viewInputOutput = (rowdata) => {
    let id = rowdata.id;
    let Appointslug = rowdata.appointmentSlug;
    window.location.href = `#!` + `${Routes.COMPARE}?id=${id}`;
  };
  const viewFolder = (rowdata) => {
    let id = rowdata.id;
    // let Appointslug = rowdata.appointmentSlug;
    window.location.href = `#!` + `${Routes.VIEWFOLDER}?id=${id}`;
  };

  const [opened, setOpened] = useState(false);

  // admitted patient room number onClick handler
  const handleClickOpen = (rowdata) => {
    addroomno.roomNumber = "";
    setSelectedAdmit(rowdata);
    setOpen(true);
  };
  const [warningOpen, setWarningOpen] = useState(false);
  const [visitRequests, setVisitRequest] = useState([]);
  const [indicators, setIndicators] = useState({});
  const handleWarningOpen = (rowdata) => {
    setVisitRequest(rowdata.visitRequests);
    setIndicators(rowdata.indicators);
    setWarningOpen(true);
  };
  const handleClosee = () => {
    setWarningOpen(false);
  };

  const handleOpenReport = (item) => {
    // setReport(true);
    //  setReport(false);
    fetchReports(item.patientSlug);
    window.location.href = `#!` + `${Routes.NEWREPORT}?slug=${item.patientSlug}`;
  };

  const handleClose = () => {
    setOpen(false);
    setReport(false);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [admitDataLoading, setadmitDataLoading] = useState(false);

  useEffect(() => {
    if (isInitialRender.current) {
      console.log("admittest_checking 10 seconds render");
      setadmitDataLoading(true);
      isInitialRender.current = false;
    }

    Api.post("/admit/search", {
      size: 20,
      page: 0,
      filterModel: {
        doctorSlug: {
          type: "equals",
          // "value": "DCT32"
          value: doctorSlug,
        },
        patientSlug: {
          type: "equals",
          // "value": "DCT32"
          value: patientSlug,
        },
        date: {
          type: "equals",
          // "value": 1686913936000
          //currentDate coming from appointment page through destructuring
          value: currentdate,
        },
      },
    }).subscribe((resp) => {
      if (resp) {
        // setFetchdata(resp.data.data.content);
        let alldatas = resp.data.data.content;

        setAdmitData(alldatas);

        setadmitDataLoading(false);
        // initPatientFormFields(alldatas);
      }
    });
  }, [currentdate, refresh]);

  // const initPatientFormFields = (data)=>{
  //     selectInputRef.current.setValue("status",data?.status );
  // }
  const [patientReports, setPatientReports] = useState([]);
  const fetchReports = (rPatientSlug) => {
    setIsLoading(true);
    DataService.fetchList("report/search", 0, 100, {
      patientSlug: {
        // values: [data && data.patientSlug],
        values: [rPatientSlug],
        filterType: "set",
      },
    }).subscribe((resp) => {
      const reports = [...resp.content];
      const newestReports = reports.reverse();
      setPatientReports(newestReports);
      setIsLoading(false);
    });
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [selectedReport, setSelectedReport] = useState([]);
  const [selectSingleImage, setSelectSingleImage] = useState(null);
  const [reportType, setReportType] = useState(null);

  const handleSelectedReport = (report) => {
    setSelectedReport(report);
    Api.get(`report/${report.slug}`).subscribe(
      (resp) => {
        setSelectSingleImage(resp.data.data);
        // setOpenReport(true);
        if (resp.data.data?.images) {
          let fileType = null;
          fileType = resp.data.data.images.map((data) => data.contenttype);
          setReportType(fileType);
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );

    // if(report?.images?.[0]){
    //   let fileType = null;
    //   fileType = report.images[0].split(".").pop()
    //   setReportType(fileType);
    // }
  };
  const handleCloseReport = useCallback(() => {
    setOpenReport(false);
  }, []);

  const handleImageClick = (val) => {
    let data = val;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };
  const [status, setStatus] = useState({});
  const [status1, setStatus1] = useState([]);
  const [openChecklistModal, setOpenChecklistModal] = useState(false);
  const [checklistData, setChecklistData] = useState([]);


  useEffect(() => {
    // Api.get('admit/status').subscribe((resp) => {
    Api.get("admit/status?admitStatus=Active").subscribe((resp) => {
      if (resp) {
        setStatus1(resp?.data?.data);
      }
    });
  }, []);

  const handleCloseChecklistModal = () => {
    setOpenChecklistModal(false);
    setStatus((prev) => ({ ...prev, [selectedAdmit.id]: oldStatus }));
  };


  const handleChangeStatus = (evt, rowdata) => {
    // setStatus(e.target.value)
    const value = evt?.target?.value;
    setStatus((prev) => ({ ...prev, [rowdata.id]: value }));
    const status_config = status1.find(item => item.name === value);
    if (status_config?.checklist) {
      setOpenChecklistModal(true);
      setChecklistData(status_config?.checklistItems);
      setOldStatus(status[rowdata.id] ?? rowdata.status);
      setNewStatus(value);
      setSelectedAdmit(rowdata);
    } else {
      const payload = {
        oldStatus: status[rowdata.id] ?? rowdata.status,
        newStatus: value,
        remarks: "",
        checklistData: []
      }
      handleSubmitChecklist(payload, rowdata.id);
    }
  };

  const handleSubmitChecklist = (payload, id) => {
    Api.post(`admit/status/${id}`, payload).subscribe((resp) => {
      SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
    });
  }

  return (
    <>
      <div style={{ maxWidth: "100%", overflowX: "auto" }}>
        {admitDataLoading ? (
          <Loader />
        ) : (
          <TableContainer
            component={Paper}
            className={className ? className : classes.container}
            style={{ minWidth: "100%", overflowX: "auto" }}
          >
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    ></span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Room
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Patient Name
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Doctor
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Number/UHID
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Diagnosis
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Plan
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Status
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle} style={{ position: "relative", left: "5px"}}>
                  <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                    BP
                  </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle} style={{ position: "relative", right: "15px"}}>
                  <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                    SPO2
                  </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle} style={{ position: "relative", right: "35px"}}>
                  <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                    PR
                  </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle} style={{ position: "relative", right: "40px"}}>
                  <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                    RR
                  </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle} style={{ position: "relative", right: "44px"}}>
                  <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                    T
                  </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle} style={{ position: "relative", right: "60px"}}>
                  <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                    PS
                  </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Consultation
                    </span>
                  </TableCell>
                  <TableCell component="th" className={classes.textStyle}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "Comfortaa",
                        fontSize: "12px",
                      }}
                    >
                      Indicators
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {admitData?.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: row.showAlert === true ? "#fcd7f3" : "",
                    }}
                  >
                    <TableCell onClick={() => handleWarningOpen(row)}>
                      <Tooltip title="Warning">
                        <span>
                          {row.showAlert === true ? (
                            <IconButton className={classes.icon}>
                              <Warning
                                style={{ fontSize: "15px", color: "yellow" }}
                              />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </span>
                      </Tooltip>
                    </TableCell>

                    <TableCell style={{ fontFamily: "Comfortaa", fontSize: "12px", cursor:  "pointer"}} onClick={() => handleClickOpen(row)}>
                      {row.roomNumber}
                    </TableCell>
                    <TableCell style={{ fontFamily: "Comfortaa", fontSize: "12px" }}>{row?.patient?.name}</TableCell>
                    <TableCell style={{ fontFamily: "Comfortaa", fontSize: "12px" }}>{row?.doctor?.name}</TableCell>
                    <TableCell style={{ fontFamily: "Comfortaa", fontSize: "12px" }}>{row?.patient?.uhid}</TableCell>
                    <TableCell style={{ fontFamily: "Comfortaa", fontSize: "12px" }}>{row.prov}</TableCell>
                    <TableCell style={{ fontFamily: "Comfortaa", fontSize: "12px" }}>{row.plan}</TableCell>
                    {/* <TableCell>{row.status}</TableCell> */}
                    <TableCell>
                      {/* <SelectInput
                                        
                                                required
                                                containerClassName={"mr"}
                                                className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                                                options={status}
                                                
                                                field="status"
                                                placeholder="status"
                                                label="status"
                                                validateOnBlur
                                                validateOnChange
                                                
                                                
                                            /> */}
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        style={{ width: "80%" }}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="filled-age-native-simple">
                            Status
                          </InputLabel>
                          <Select
                            id="demo-simple-select-outlined"
                            style={{ width: 180 }}
                            required
                            value={status[row.id] ?? row.status}
                            onChange={(e) => {
                              setStatus((prev) => ({
                                ...prev,
                                [row.id]: e.target.value,
                              }));
                              handleChangeStatus(e, row);
                            }}
                            label="status"
                          >
                            {/* <MenuItem value={'ADMITTED'}>ADMITTED</MenuItem> */}
                            {status1?.map((option, index) => {
                              return (
                                <MenuItem key={index} value={option?.name}>
                                  {option?.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                    <TableCell style={{ position: "relative", left: "5px", fontFamily: "Comfortaa", fontSize: "12px" }}>
                      {row.vitals?.bloodPressure}
                    </TableCell>
                    <TableCell style={{ position: "relative", right: "15px", fontFamily: "Comfortaa", fontSize: "12px" }}>
                      {row.vitals?.oxygenSaturation}
                    </TableCell>
                    <TableCell style={{ position: "relative", right: "35px", fontFamily: "Comfortaa", fontSize: "12px" }}>
                      {row.vitals?.pulseRate}
                    </TableCell>
                    <TableCell style={{ position: "relative", right: "40px", fontFamily: "Comfortaa", fontSize: "12px" }}>
                      {row.vitals?.respirationRate}
                    </TableCell>
                    <TableCell style={{ position: "relative", right: "44px", fontFamily: "Comfortaa", fontSize: "12px" }}>
                      {row.vitals?.temperature}
                    </TableCell>
                    <TableCell style={{ position: "relative", right: "60px", fontFamily: "Comfortaa", fontSize: "12px" }}>
                      {row.vitals?.ps}
                    </TableCell>
                    <TableCell>
                      <Box display="flex">
                        <Tooltip title="File">
                          <span>
                            <IconButton
                              onClick={() => viewFolder(row)}
                              className={classes.icon}
                              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} // Center the icon
                            >
                              <DescriptionIcon style={{ fontSize: "15px" }} />
                            </IconButton>
                          </span>
                        </Tooltip>

                        <Tooltip
                          title="Report"
                          onClick={() => handleOpenReport(row)}
                        >
                          <span>
                            <IconButton
                                className={classes.icon}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} // Center the icon
                              >
                                <AssessmentIcon style={{ fontSize: "15px" }} />
                            </IconButton>
                          </span>
                        </Tooltip>

                        <Tooltip title="Input Output vitals">
                          <span>
                            <IconButton
                                  onClick={() => viewInputOutput(row)}
                                  className={classes.icon}
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} // Center the icon
                                >
                                  <AssignmentIcon style={{ fontSize: "15px" }} />
                            </IconButton>
                          </span>
                        </Tooltip>

                        <Tooltip title="Billing">
                          <span>
                          <IconButton
                                  onClick={() => viewBilling(row)}
                                  className={classes.icon}
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} // Center the icon
                                >
                                  <AccountBalanceWalletIcon style={{ fontSize: "15px" }} />
                            </IconButton>
                          </span>
                        </Tooltip>

                        <Tooltip title="View Admitted">
                          <span>
                          <IconButton
                                  onClick={() => viewadmitted(row)}
                                  className={classes.icon}
                                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} // Center the icon
                                >
                                  <PeopleIcon style={{ fontSize: "15px" }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>

                    <TableCell>
                      {row?.alarmSettings !== null &&
                      row.indicators !== null ? (
                        <Box display="flex">
                          {row.indicators?.passedMotion !== null ? (
                            <Tooltip title="Passed Motion">
                              <span>
                                <IconButton
                                  className={classes.icon}
                                  style={{
                                    backgroundColor:
                                      row.indicators == null ||
                                      row.indicators?.passedMotion === false ||
                                      row.indicators?.passedMotion === null
                                        ? "yellow"
                                        : "black",
                                  }}
                                >
                                  {/* TODO: Add Icon here */}
                                  {/* <Terrain style={{ fontSize: "15px" }} /> */}
                                  <img
                                    src={Poop}
                                    style={{
                                      fontSize: "15px",
                                      height: "1em",
                                      width: "1em",
                                      filter:
                                        row.indicators == null ||
                                        row.indicators?.passedMotion ===
                                          false ||
                                        row.indicators?.passedMotion === null
                                          ? "yellow"
                                          : "black",
                                    }}
                                    alt=""
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {row.indicators?.passedFlatus !== null ? (
                            <Tooltip title="Passed Flatus">
                              <span>
                                <IconButton
                                  className={classes.icon}
                                  style={{
                                    color:
                                      row.indicators == null ||
                                      row.indicators?.passedFlatus === false ||
                                      row.indicators?.passedFlatus === null
                                        ? "black"
                                        : "white",
                                    backgroundColor:
                                      row.indicators == null ||
                                      row.indicators?.passedFlatus === false ||
                                      row.indicators?.passedFlatus === null
                                        ? "yellow"
                                        : "black",
                                  }}
                                >
                                  {/* TODO: icon here */}
                                  {/* <AirSharp
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  /> */}
                                  <img
                                    src={Flatus}
                                    style={{
                                      fontSize: "15px",
                                      height: "1em",
                                      width: "1em",
                                      filter:
                                        row.indicators == null ||
                                        row.indicators?.passedMotion ===
                                          false ||
                                        row.indicators?.passedMotion === null
                                          ? "yellow"
                                          : "black",
                                    }}
                                    alt=""
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {row.indicators?.beddingDone !== null ? (
                            <Tooltip title="Bedding Done">
                              <span>
                                <IconButton
                                  className={classes.icon}
                                  style={{
                                    color:
                                      row.indicators == null ||
                                      row.indicators?.beddingDone === false ||
                                      row.indicators?.beddingDone === null
                                        ? "black"
                                        : "white",
                                    backgroundColor:
                                      row.indicators == null ||
                                      row.indicators?.beddingDone === false ||
                                      row.indicators?.beddingDone === null
                                        ? "yellow"
                                        : "black",
                                  }}
                                >
                                  {/* TODO: Add icon here */}
                                  {/* <BedSharp
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  /> */}
                                  <img
                                    src={Bedding}
                                    style={{
                                      fontSize: "15px",
                                      height: "1em",
                                      width: "1em",
                                      filter:
                                        row.indicators == null ||
                                        row.indicators?.passedMotion ===
                                          false ||
                                        row.indicators?.passedMotion === null
                                          ? "yellow"
                                          : "black",
                                    }}
                                    alt=""
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {row.indicators?.oralCareDone !== null ? (
                            <Tooltip title="Oral Care">
                              <span>
                                <IconButton
                                  className={classes.icon}
                                  style={{
                                    backgroundColor:
                                      row.indicators == null ||
                                      row.indicators?.oralCareDone === false ||
                                      row.indicators?.oralCareDone === null
                                        ? "yellow"
                                        : "black",
                                  }}
                                >
                                  <img
                                    src={OralCare}
                                    style={{
                                      width: "1em",
                                      height: "1em",
                                      fontSize: "15px",
                                      filter:
                                        row.indicators == null ||
                                        row.indicators?.oralCareDone ===
                                          false ||
                                        row.indicators?.oralCareDone === null
                                          ? "yellow"
                                          : "black",
                                    }}
                                    alt=""
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {row.indicators?.bathingDone !== null ? (
                            <Tooltip title="Bathing Done">
                              <span>
                                <IconButton
                                  className={classes.icon}
                                  style={{
                                    color:
                                      row.indicators == null ||
                                      row.indicators?.bathingDone === false ||
                                      row.indicators?.bathingDone === null
                                        ? "black"
                                        : "white",
                                    backgroundColor:
                                      row.indicators == null ||
                                      row.indicators?.bathingDone === false ||
                                      row.indicators?.bathingDone === null
                                        ? "yellow"
                                        : "black",
                                  }}
                                >
                                  <BathtubSharp
                                    style={{
                                      fontSize: "15px",
                                      height: "1em",
                                      width: "1em",
                                      filter:
                                        row.indicators == null ||
                                        row.indicators?.passedMotion === false ||
                                        row.indicators?.passedMotion === null
                                          ? "yellow"
                                          : "black",
                                    }}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </Box>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TablePagination
                  rowsPerPageOptions={[5]}
                  count={admitData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
          position="fixed"
        >
          <DialogContent sx={{ width: 600 }}>
            <Typography variant="h5">Room Numbers</Typography>
            <ThemeProvider theme={myTheme}>
              <ToggleButtonGroup
                name="roomNumber"
                value={addroomno.roomNumber}
                exclusive
                onChange={handlechangeroomno}
                aria-label="text alignment"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {roomsLoading ? (
                  <CircularProgress />
                ) : roomsError ? (
                  <Typography color="error">Error loading rooms</Typography>
                ) : (
                  allActiveRooms?.map((item, index) => {
                    if (item.roomNumber == null) {
                      return null;
                    } else {
                      return (
                        <ToggleButton
                          name="roomNumber"
                          key={index}
                          value={item.roomNumber}
                          aria-label="centered"
                          style={{ width: "56px" }}
                        >
                          {item.roomNumber}
                        </ToggleButton>
                      );
                    }
                  })
                )}
              </ToggleButtonGroup>
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveandUpdateRoom}>Save</Button>
          </DialogActions>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog> */}
        <RoomSelectionModal
        open={open}
        handleClose={handleClose}
        roomsLoading={roomsLoading}
        roomsError={roomsError}
        allActiveRooms={allActiveRooms}
        addroomno={addroomno}
        handlechangeroomno={handlechangeroomno}
        handleSaveandUpdateRoom={handleSaveandUpdateRoom}
        myTheme={myTheme}
        classes={classes} // Ensure you pass the classes prop for styling
        />
        {/* view report dialog */}
        <Dialog
          open={openRep}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
          position="fixed"
        >
          <DialogContent sx={{ width: 600 }}>
            {isLoading ? (
              <CircularProgress
                disableShrink
                className={classes.loaderStyle}
                size={35}
              />
            ) : (
              <>
                <Typography variant="h4">Report</Typography>
                <Grid container item sx={{ width: 500 }}>
                  <Paper className={classes.containerBox} sx={{ width: 500 }}>
                    {/* <MiniHeader title="Report(s)" /> */}
                    {patientReports.length > 0 ? (
                      <Box
                        className={clsx(classes.presBox, globalClass.srollBar)}
                        style={{ height: "300px", paddingLeft: "20px" }}
                      >
                        {patientReports.map((data) => (
                          <div
                            className={classes.reportCard}
                            key={data.id}
                            onClick={() => handleSelectedReport(data)}
                          >
                            <h6 className={classes.title}>
                              {`${data.reportName ?? ""} / (${
                                getFormattedDate(
                                  data.reportDate,
                                  "Do MMMM YYYY"
                                ) ?? ""
                              })`}
                            </h6>
                          </div>
                        ))}
                      </Box>
                    ) : (
                      <div className={classes.noReport}>
                        <Typography variant="h6">No Reports Found</Typography>
                      </div>
                    )}
                  </Paper>
                </Grid>
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>

        <StatusChecklistModal 
          open={openChecklistModal}
          setOpen={setOpenChecklistModal}
          handleClose={handleCloseChecklistModal}
          data={checklistData}
          classes={classes}
          oldStatus={oldStatus}
          newStatus={newStatus}
          onSubmitChecklist={handleSubmitChecklist}
          id={selectedAdmit?.id}
        />

        <Modal
          open={opened}
          onClose={handleClosed}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box>
        </Modal>
      </div>
      <Dialog
        className={classes.dialogBackground}
        fullScreen
        open={openReport}
        onClose={handleCloseReport}
      >
        <div className={classes.closeContainer}>
          <div className={classes.reportClose} onClick={handleCloseReport}>
            <p className={classes.closeHeading} style={{ marginTop: "14px" }}>
              Close
            </p>
          </div>
          <div className={classes.reportTitle}>
            <span>
              {`${selectSingleImage?.reportName ?? ""} / ${
                getFormattedDate(
                  selectSingleImage?.reportDate,
                  "Do MMMM YYYY"
                ) ?? ""
              }/ 
                  Value: ${selectSingleImage?.value ?? ""}`}
            </span>
          </div>
        </div>
        {selectSingleImage?.images ? (
          selectSingleImage?.images?.length === 1 ? (
            selectSingleImage?.images[0].contenttype === "application/pdf" ? (
              <iframe
                title="Report picture"
                src={selectSingleImage.images[0].content}
                alt="Report"
                className={classes.reportImage}
                onClick={() =>
                  handleImageClick(selectSingleImage.images[0].content)
                }
                style={{ cursor: "pointer" }}
              />
            ) : (
              <img
                title="Report picture"
                src={selectSingleImage.images[0].content}
                alt="Report"
                className={classes.reportImage}
                onClick={() =>
                  handleImageClick(selectSingleImage.images[0].content)
                }
                style={{ cursor: "pointer" }}
              />
            )
          ) : (
            <div style={{}} className={classes.multipleImageContainer}>
              <Slider {...settings}>
                {selectSingleImage.images.map((im, index) => {
                  return (
                    <div key={index} style={{ width: "100%", height: "90vh" }}>
                      {im?.contenttype === "application/pdf" ? (
                        <iframe
                          title="Report picture"
                          src={im.content}
                          alt="Report"
                          className={classes.reportImage}
                          onClick={() => handleImageClick(im?.content)}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "90vh",
                          }}
                        />
                      ) : (
                        <img
                          title="Report picture"
                          src={im?.content}
                          alt="Report"
                          className={classes.reportMultipleImage}
                          onClick={() => handleImageClick(im?.content)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
          )
        ) : (
          <div className={classes.noReportImage}>
            Report image is not available.
          </div>
        )}
      </Dialog>
      {/* warning modal  */}
      <WarningDialog
        open={warningOpen}
        onClose={handleClosee}
        visitRequests={visitRequests}
        indicators={indicators}
      />
    </>
  );
};
export default AdmittedList;
